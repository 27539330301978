import { City, GeoPoint, Hint, Navigation } from "@cityscour/types"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import challengeInfo from './../images/challenge_info.png'
import hintText from './../images/hint_text.png'
import { useCallback, useEffect, useState } from "react"
import { Draggable } from "react-beautiful-dnd"
import { getUploadedImageUrl } from "../functions/functions"
import useInfoModal from "../hooks/useInfoModal"
import FieldInfoModal from "./FieldInfoModal"
import LocationMap from "./LocationMap"
import { ApolloQueryResult, useMutation, useQuery } from "@apollo/client"
import { GET_CITIES } from "../graphql/queries"
import { DELETE_ARTICLE_CHALLENGE, DELETE_STOP_CHALLENGE, UPDATE_ARTICLE_CHALLENGE, UPDATE_STOP_CHALLENGE } from "../graphql/mutations"
import { useParams } from "react-router-dom"
import { RemoveButton } from "./RemoveButton"
import { InfoButton } from "./InfoButton"
import { SaveButton } from "./SaveButton"
import { TextInput } from "./TextInput"
import { FileInput } from "./FileInput"
import { Subtitle } from "./Subtitle"
import { FlexContainer } from "./FlexContainer"
import { FileWrapper } from "./FileWrapper"

interface Props {
  idx: number
  challenge: any
  isActive: number[] | undefined
  handleActive: React.Dispatch<React.SetStateAction<number[] | undefined>>
  highlightedChallengeId?: string
  isDisabled: boolean
  refetch: () => Promise<ApolloQueryResult<any>>
  type: 'Article' | 'Stop'
  stopId?: string
}

const ChallengeItem: React.FC<Props> = ({
  idx, 
  challenge, 
  isActive, 
  handleActive, 
  highlightedChallengeId,
  isDisabled,
  refetch,
  type,
  stopId
}) => {
  const { articleId } = useParams<string>();

  const [question, setQuestion] = useState('')
  const [explanation, setExplanation] = useState('')
  const [image, setImage] = useState('')
  const [answerSet, setAnswerSet] = useState<any>()
  const [navigation, setNavigation] = useState<Navigation | null>(null)
  const [hint, setHint] = useState<Hint | null>(null)
  const [dataChanged, setDataChanged] = useState(false)
  const [currentGeolocation, setCurrentGeolocation] = useState<GeoPoint>()

  const { data: allCities } = useQuery(GET_CITIES)

  const [updateArticleChallenge] = useMutation(UPDATE_ARTICLE_CHALLENGE)
  const [updateStopChallenge] = useMutation(UPDATE_STOP_CHALLENGE)
  const [deleteChallengeFromArticle] = useMutation(DELETE_ARTICLE_CHALLENGE)
  const [deleteChallengeFromStop] = useMutation(DELETE_STOP_CHALLENGE)

  const {fieldToShow, setFieldToShow, showFieldInfoModal, setShowFieldInfoModal, handleInfoModalClose} = useInfoModal()

  const handleUpdateChallenge = async () => {
    if (type === 'Article') {
      await updateArticleChallenge({
        variables: {
          input: {
            articleId,
            challengeId: challenge.id,
            challenge: {
              question,
              answerSet,
              explanation,
              image,
              navigation: navigation ?? null,
              hint: hint ?? null,
            }
          }
        }
      })
    }
    if (type === 'Stop') {
      await updateStopChallenge({
        variables: {
          input: {
            stopId,
            challengeId: challenge.id,
            challenge: {
              question,
              answerSet,
              explanation,
              image,
              navigation: navigation ?? null,
              hint: hint ?? null,
            }
          }
        }
      })
    }
    await refetch()
  }

  const handleRemoveChallenge = useCallback(
    async (challengeId: string) => {
      if (type === 'Article') {
        await deleteChallengeFromArticle({
          variables: {
            input: {
              articleId,
              challengeId
            }
          }
        })
      }
      if (type === 'Stop') {
        await deleteChallengeFromStop({
          variables: {
            input: {
              stopId,
              challengeId
            }
          }
        })
      }
      await refetch()
    },
    [articleId, stopId, type]
  );

  const handleChangeLocation = useCallback((geolocation: GeoPoint) => {
    if (!navigation || isDisabled) return
    setNavigation({...navigation, geolocation})
  }, [isDisabled, navigation?.name])

  useEffect(() => {
    question !== challenge.question
    || explanation !== challenge.explanation
    || image !== challenge.image
    || answerSet.kind !== challenge.answerSet.kind
    || JSON.stringify(answerSet?.correct) !== JSON.stringify(challenge.answerSet.correct)
    || (answerSet.kind === 'MultipleChoiceAnswerSet' && challenge.answerSet.kind === 'MultipleChoiceAnswerSet' 
        && JSON.stringify(answerSet?.options) !== JSON.stringify(challenge.answerSet.options))
    || navigation?.name !== challenge.navigation?.name
    || navigation?.image !== challenge.navigation?.image
    || navigation?.geolocation.latitude !== challenge.navigation?.geolocation.latitude
    || navigation?.geolocation.longitude !== challenge.navigation?.geolocation.longitude
    || hint?.text !== challenge.hint?.text
    || hint?.image !== challenge.hint?.image
      ? setDataChanged(true)
      : setDataChanged(false)
  }, [question, explanation, image, answerSet, hint, navigation, challenge])

  useEffect(() => {
    setQuestion(challenge.question)
    setExplanation(challenge.explanation)
    setAnswerSet(challenge.answerSet)
    setImage(challenge.image)
    setNavigation(challenge.navigation ?? null)
    setHint(challenge.hint ?? null)
  }, [challenge])

  useEffect(() => {
    if (!articleId || !allCities) return
    const city = allCities.cities.find((city: City) => city.tiles.some((t) => t.id === articleId))
    city && setCurrentGeolocation(city.geofence.geolocation)
  }, [articleId, allCities])

  window.onbeforeunload = () => {
    if (dataChanged)
      return "Are you sure you want to leave? You have an unsaved data";
  };

  return (
    <>
      <li 
        className="mb-1"
      >
        <Draggable draggableId={challenge.id} index={idx}>
          {(draggableProvided) => 
            <div
              {...draggableProvided.dragHandleProps}
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps} 
              className="is-flex is-align-items-center"
            >
              <div 
                className={`column is-flex is-justify-content-space-between is-align-items-center box my-0 mr-3
                  ${(highlightedChallengeId === challenge.id) ? 'has-background-light' : ''}`}
                onClick={()=>handleActive(prev=>{
                  if (prev) {
                    return prev.includes(idx)?prev.filter((item) => item!==idx):[...prev, idx]
                  }
                  else return [idx]
                })}
              >
                {challenge.id}
                <FontAwesomeIcon icon={faGripVertical} />
              </div>
              {!isDisabled && <RemoveButton handleClick={() => handleRemoveChallenge(challenge.id)} />}
            </div>
          }
        </Draggable>
        {isActive && isActive.includes(idx) && (
          <div className="column box">
            {dataChanged && !isDisabled && (
              <SaveButton handleClick={handleUpdateChallenge}>
                Save Challenge
              </SaveButton>
            )}
            <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
              <FlexContainer>
                <label>Question</label>
                <InfoButton handleClick={() => {
                  setShowFieldInfoModal(true)
                  setFieldToShow({limitation: 100, description: 'Please, provide a question of current challenge', image: challengeInfo})
                }}/>
              </FlexContainer>
              <TextInput placeholder="name" disabled={isDisabled} value={question} handleChange={(e) => setQuestion(e.target.value)}/>
            </div>
            <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
              <FlexContainer>
                <label>Explanation</label>
                <InfoButton handleClick={() => {
                  setShowFieldInfoModal(true)
                  setFieldToShow({limitation: 100, description: 'Please, provide an explanation of current challenge', image: challengeInfo})
                }}/>
              </FlexContainer>
              <TextInput disabled={isDisabled} placeholder="name" value={explanation} handleChange={(e) => setExplanation(e.target.value)} />
            </div>
            <div className="column is-one-quarter px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
              <label>Image</label>
              {image && <div className="is-relative">
                <img src={image} alt='challenge' />
                {!isDisabled && <RemoveButton handleClick={() => setImage('')} additionalStyle={{position: 'absolute', right: '5px', top: '0'}} />}
              </div>}
              {!isDisabled && <FileWrapper>
                <label className="file-label">
                  <FileInput handleChange={async (e) => {
                    const url = await getUploadedImageUrl(e)
                    setImage(url)
                  }}/>
                  <span className="file-cta">
                    <span className="file-label">
                      {image ? 'Change Image' : 'Add Image'}
                    </span>
                  </span>
                </label>
              </FileWrapper>}
            </div>
            <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
              <Subtitle>Answer Set</Subtitle>
              <div className="column is-one-fifth px-0 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                <div className="select">
                  <select
                    disabled={isDisabled}
                    onChange={(e) => {
                      switch (e.target.value) {
                        case 'FreeNumberAnswerSet':
                          return setAnswerSet({kind: e.target.value, correct: '0'});
                        case 'FreeTextAnswerSet':
                          return setAnswerSet({kind: e.target.value, correct: 'Correct'});
                        case 'MultipleChoiceAnswerSet':
                          return setAnswerSet({kind: e.target.value, correct: '0', options: ['Option 1', 'Option 2']});
                      }
                    }}
                    style={{ width: "100%" }}
                    value={answerSet?.kind}
                  >
                    <option value="FreeTextAnswerSet">FreeTextAnswerSet</option>
                    <option value="FreeNumberAnswerSet">FreeNumberAnswerSet</option>
                    <option value="MultipleChoiceAnswerSet">MultipleChoiceAnswerSet</option>
                  </select>
                </div>
              </div>
              {answerSet && answerSet.kind === 'MultipleChoiceAnswerSet' &&
                <ul>
                  <label>Options</label>
                  {answerSet.options.map((opt: any, idx: number) => (
                    <li className="column is-half is-flex is-align-items-center" key={idx}>
                      <TextInput 
                        marginRight={true}
                        disabled={isDisabled}
                        value={opt}
                        handleChange={(e) => {
                          const newOptions = [...answerSet.options]
                          newOptions[idx] = e.target.value
                          setAnswerSet({...answerSet, options: newOptions})
                        }}
                      />
                      {!isDisabled && <RemoveButton handleClick={() => setAnswerSet({...answerSet, options: answerSet.options.filter((item: any) => item !== opt)})} />}
                    </li>
                  ))}
                  <button
                    disabled={isDisabled}
                    onClick={() => setAnswerSet({...answerSet, options: answerSet.options ? [...answerSet.options, 'New option'] : ['New optiin']})}
                    className="button is-light mt-5 mr-5"
                  >
                    Add Option
                  </button>
                </ul>
              }
              <div className="column is-full mt-3 mb-5">
                <FlexContainer>
                  <label>Correct</label>
                  <InfoButton handleClick={() => {
                    setShowFieldInfoModal(true)
                    setFieldToShow({limitation: 100, description: 'Please, provide a correct answer of current challenge (or in MultipleAnswerSet case provide an index of correct answer, where first is 0', image: challengeInfo})
                  }} />
                </FlexContainer>
                {answerSet && (typeof answerSet.correct !== 'object' 
                  ? (<div className="column is-one-quarter px-0 py-0 mt-3 mb-3 is-flex">
                    <TextInput 
                      handleChange={(e) => setAnswerSet({...answerSet, correct: e.target.value})}
                      placeholder="name"
                      value={answerSet.correct}
                      disabled={isDisabled}
                    />
                  </div>)
                  : answerSet.correct.map((ca: any, idx: number) => (
                    <div key={idx} className="column is-one-quarter px-0 py-0 mt-3 mb-3 is-flex is-align-items-center">
                      <TextInput 
                        marginRight={true}
                        disabled={isDisabled}
                        value={ca}
                        placeholder="name"
                        handleChange={(e) => {
                          const newCorrect = [...answerSet.correct]
                          newCorrect[idx] = e.target.value
                          setAnswerSet({...answerSet, correct: newCorrect})
                        }}
                      />
                      {!isDisabled && <RemoveButton handleClick={() => {
                        if (typeof answerSet.correct !== 'object') return
                        let newCorrect: string | string[]
                        newCorrect = [...answerSet.correct.filter((cor: any) => cor !== ca)]
                        newCorrect = newCorrect.length > 1 ? newCorrect : newCorrect.toString()
                        setAnswerSet({...answerSet, correct: newCorrect})
                      }}/>}
                    </div>
                  ))
                )}
                {answerSet && (<button
                  disabled={isDisabled}
                  onClick={() => {
                    typeof answerSet.correct === 'object' 
                      ? answerSet.kind === 'FreeTextAnswerSet'
                        ? setAnswerSet({...answerSet, correct: [...answerSet.correct, 'New Correct']})
                        : setAnswerSet({...answerSet, correct: [...answerSet.correct, 0]})
                      : answerSet.kind === 'FreeTextAnswerSet'
                        ? setAnswerSet({...answerSet, correct: [answerSet.correct, 'New Correct']})
                        : setAnswerSet({...answerSet, correct: [answerSet.correct, 0]})
                  }}
                  className="button is-light mt-5 is-align-self-flex-start"
                >
                  Add Correct Answer
                </button>)}
              </div>
            </div>
            <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
              <Subtitle>Navigation</Subtitle>
              {navigation && (
                <>
                  <div className="column is-one-quarter px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                    <label>Name</label>
                    <TextInput 
                      disabled={isDisabled}
                      placeholder="navigation name"
                      value={navigation.name}
                      handleChange={(e) => setNavigation({...navigation, name: e.target.value})}
                    />
                  </div>
                  <div className="column is-one-quarter px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                    <label>Image</label>
                    {navigation.image && <div className="is-relative">
                      <img src={navigation.image} alt='challenge' />
                      {!isDisabled && <RemoveButton handleClick={() => setNavigation({...navigation, image: ''})} additionalStyle={{position: 'absolute', right: '5px', top: '0'}} />}
                    </div>}
                    {!isDisabled && <FileWrapper>
                      <label className="file-label">
                        <FileInput handleChange={async (e) => {
                          const url = await getUploadedImageUrl(e)
                          setNavigation(navigation ? {...navigation, image: url} : null)
                        }}/>
                        <span className="file-cta">
                          <span className="file-label">
                            {navigation.image ? 'Change Image' : 'Add Image'}
                          </span>
                        </span>
                      </label>
                    </FileWrapper>}
                  </div>
                  {navigation && <LocationMap 
                    geolocation={navigation.geolocation} 
                    handleChangeLocation={handleChangeLocation} 
                    isDisabled={isDisabled} 
                  />}
                </>
              )}
              <button
                disabled={isDisabled}
                onClick={() => {
                  if (isDisabled) return
                  navigation ? setNavigation(null) : setNavigation({
                    name: 'Challenge Navigation',
                    geolocation: {
                      latitude: currentGeolocation?.latitude ?? 50,
                      longitude: currentGeolocation?.longitude ?? 50
                    }
                  })
                }}
                className="button is-light mt-5 is-align-self-flex-start"
              >
                {navigation ? 'Remove Navigation' : 'Add Navigation'}
              </button>
            </div>
            <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
              <Subtitle>Hint</Subtitle>
              {hint && (
                <>
                  <div className="column is-one-quarter px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                    <FlexContainer>
                      <label>Text</label>
                      <InfoButton handleClick={() => {
                        setShowFieldInfoModal(true)
                        setFieldToShow({limitation: 100, description: 'Please provide a text for current challenge hint', image: hintText})
                      }}/>
                    </FlexContainer>
                    <TextInput 
                      disabled={isDisabled}
                      placeholder="hint name"
                      value={hint.text}
                      handleChange={(e) => setHint({...hint, text: e.target.value})}
                    />
                  </div>
                  <div className="column is-one-quarter px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                    <label>Image</label>
                    {hint.image && <div className="is-relative">
                      <img src={hint.image} alt='challenge' />
                      {!isDisabled && <RemoveButton handleClick={() => setHint({...hint, image: ''})} additionalStyle={{position: 'absolute', right: '5px', top: '0'}} />}
                    </div>}
                    {!isDisabled && <FileWrapper>
                      <label className="file-label">
                        <FileInput handleChange={async (e) => {
                          const url = await getUploadedImageUrl(e)
                          setHint(hint ? {...hint, image: url} : null)
                        }}/>
                        <span className="file-cta">
                          <span className="file-label">
                            {hint.image ? 'Change Image' : 'Add Image'}
                          </span>
                        </span>
                      </label>
                    </FileWrapper>}
                  </div>
                </>
              )}
              <button
                disabled={isDisabled}
                onClick={() => {
                  if (isDisabled) return
                  hint ? setHint(null) : setHint({text: 'Hint text'})
                }}
                className="button is-light mt-5 is-align-self-flex-start"
              >
                {hint ? 'Remove Hint' : 'Add Hint'}
              </button>
            </div>
          </div>
        )}
      </li>
      {showFieldInfoModal && fieldToShow && <FieldInfoModal fieldToShow={fieldToShow} handleModalClose={handleInfoModalClose} />}
     </>
  )
}

export default ChallengeItem