import { useQuery } from "@apollo/client"
import {
    OwnedScour,
  Scour,
  voidReturnFunction,
} from "@cityscour/types"
import { useState } from "react"
import { GET_SCOURS } from "../graphql/queries"
  
interface Props {
  handleModalClose: voidReturnFunction
  handleAddApprove: (id: string, pinsAmount: number) => void
  ownedScours: OwnedScour[] | undefined
}
  
const AddOwnedScourModal: React.FC<Props> = ({
  handleAddApprove,
  handleModalClose,
  ownedScours,
}) => {
  const [scourId, setScourId] = useState('')
  const [pinsAmount, setPinsAmount] = useState(0)

  const { data: allScours } = useQuery(GET_SCOURS)
  
  return (
    <>
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Add Owned Scour</p>
            <button
              onClick={handleModalClose}
              className="delete"
              aria-label="close"
            />
          </header>
          <section className="modal-card-body">
            <div>
              <div className="mr-6 mb-6 is-flex is-flex-direction-column">
                <div className="is-flex is-align-items-center mb-2">
                  <span>Scour: </span>
                  <div className="select mx-5">
                    <select
                      onChange={(e) => {
                        setScourId(e.target.value)
                      }}
                      style={{ width: "100%" }} 
                      value={scourId}
                    >
                      <option value="">null</option>
                      {allScours?.scours
                        ?.filter(
                          (sc: Scour) => sc.id !== (ownedScours?.find(
                            (oS) => oS.scour?.id === sc.id
                          ))?.scour?.id
                        )
                        .map((sc: Scour) => (
                          <option key={sc.id} value={sc.id}>{sc.name}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <label className="is-flex is-align-items-center my-1">
                  <span className="is-flex-shrink-0">Pins amount: </span>
                  <input
                    type="number"
                    className="input ml-3"
                    value={pinsAmount}
                    min={0}
                    max={99}
                    onChange={(e) => setPinsAmount(Number(e.target.value))}
                  />
                </label>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={() => handleAddApprove(scourId, pinsAmount)}
              className="button is-success"
              disabled={!scourId}
            >
              Submit
            </button>
            <button onClick={handleModalClose} className="button">
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </>
  )
}

export default AddOwnedScourModal