import { voidReturnFunction } from "@cityscour/types"

interface SaveButtonProps {
  handleClick: voidReturnFunction
}

export const SaveButton: React.FC<SaveButtonProps> = ({ handleClick, children }) => (
  <button
    style={{position: 'fixed', bottom: '20px', right: '20px', zIndex: '251'}}
    onClick={handleClick}
    className='button is-primary'
  >
    {children}
  </button>
)