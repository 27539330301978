import { Challenge, CityLocation, GeoPoint, Stop, TextContent } from "@cityscour/types"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"
import locationDescription from './../images/location_description.png'
import locationNavigationInfo from './../images/location_navigation_info.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useState } from "react"
import MDEditor from '@uiw/react-md-editor'
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import { getUploadedImageUrl, isCityLocation } from "../functions/functions"
import ChallengeItem from "./ChallengeItem"
import LocationMap from "./LocationMap"
import useInfoModal from "../hooks/useInfoModal"
import FieldInfoModal from "./FieldInfoModal"
import { ApolloQueryResult, useMutation } from "@apollo/client"
import { ADD_STOP_CHALLENGE, DELETE_SCOUR_STOP, UPDATE_SCOUR_STOP, UPDATE_STOP_CHALLENGE } from "../graphql/mutations"
import { useParams } from "react-router-dom"
import { RemoveButton } from "./RemoveButton"
import { InfoButton } from "./InfoButton"
import { SaveButton } from "./SaveButton"
import { TextInput } from "./TextInput"
import { FileInput } from "./FileInput"
import { Subtitle } from "./Subtitle"
import { FlexContainer } from "./FlexContainer"
import { FileWrapper } from "./FileWrapper"

interface ChallengeWithId extends Challenge {
  id: string
}

interface Props {
  idx: number
  stop: Stop
  isActive: number[] | undefined
  handleActive: React.Dispatch<React.SetStateAction<number[] | undefined>>
  highlightedStopId?: string
  isDisabled: boolean
  refetch: () => Promise<ApolloQueryResult<any>>
}

interface Story {
  text: string
  image: string
  order: number
}

interface ExtendedCityLocation extends CityLocation {
  stories: Story[]
  lastStoryImg?: string
}

const StopItem: React.FC<Props> = ({
  idx, 
  stop, 
  isActive, 
  handleActive, 
  highlightedStopId,
  isDisabled,
  refetch
}) => {
  const { scourId } = useParams<string>() 

  const [challenges, setChallenges] = useState<ChallengeWithId[]>()
  const [navigationInfo, setNavigationInfo] = useState<string>()
  const [name, setName] = useState('')
  const [description, setDescription] = useState<TextContent>()
  const [geopoint, setGeopoint] = useState<GeoPoint>()
  const [stories, setStories] = useState<Story[]>([])
  const [lastStoryImage, setLastStoryImage] = useState<string>()
  const [isChallengeActive, setIsChallengeActive] = useState<number[]>()
  const [dataChanged, setDataChanged] = useState(false)
  const [beforeLocation, setBeforeLocation] = useState<ExtendedCityLocation>()
  const [highlightedChallengeId, setHighlightedChallengeId] = useState('')

  const [updateStop] = useMutation(UPDATE_SCOUR_STOP)
  const [deleteStop] = useMutation(DELETE_SCOUR_STOP)
  const [addChallengeToStop] = useMutation(ADD_STOP_CHALLENGE)
  const [updateStopChallenge] = useMutation(UPDATE_STOP_CHALLENGE)

  const {showFieldInfoModal, setShowFieldInfoModal, fieldToShow, setFieldToShow, handleInfoModalClose} = useInfoModal()

  const handleDragEnd = useCallback(async (res: DropResult) => {
    if (!challenges || isDisabled) return
    const {destination, source} = res
    if (!destination) return
    if (destination.droppableId !== source.droppableId || destination.index === source.index) return

    let draggableChallenge = challenges[source.index]
    let temp = [...challenges]
    const element = temp.splice(temp.indexOf(draggableChallenge), 1)[0];
    temp.splice(destination.index, 0, element);
    setChallenges(temp)

    await updateStopChallenge({
      variables: {
        input: {
          stopId: stop.id,
          challengeId: challenges[source.index].id,
          challenge: {
            order: destination.index + 1
          }
        }
      }
    })
    await refetch()
  }, [challenges, isDisabled, stop.id])

  const handleUpdateStop = useCallback(async () => {
    await updateStop({
      variables: {
        input: {
          stopId: stop.id,
          scourId,
          location: {
            name,
            description,
            geolocation: geopoint,
            stories,
            lastStoryImg: lastStoryImage
          },
          navigationInfo
        }
      }
    })
    await refetch()
  }, [stop.id, scourId, name, description, geopoint, stories, lastStoryImage, navigationInfo])

  const handleRemoveCurrentStop = useCallback(async (id: string) => {
    await deleteStop({
      variables: {
        input: {
          scourId,
          stopId: id
        }
      }
    })
    await refetch()
  }, [scourId])

  const handleChangeLocation = useCallback((geolocation: GeoPoint) => {
    if (isDisabled) return
    setGeopoint(geolocation)
  }, [isDisabled])

  const handleAddChallenge = useCallback((stop: Stop) => {
    addChallengeToStop({
      variables: {
        input: {
          stopId: stop.id,
          challenge: {
            question: 'Challenge Question',
            explanation: 'Challenge explanation',
            answerSet: {
              kind: 'FreeTextAnswerSet',
              correct: 'answer',
            },
          }
        }
      }
    }).then(async (res) => {
      await refetch()
      setHighlightedChallengeId(res.data.addStopChallenge.id)
    })
    setHighlightedChallengeId('')
  }, [])

  useEffect(() => {
    setChallenges(stop.challenges as ChallengeWithId[])
    setNavigationInfo(stop.navigationInfo)
  }, [stop])

  useEffect(() => {
    if (!beforeLocation || !description) return

    const descriptionChanged = 
      JSON.stringify(description.images) !== JSON.stringify(beforeLocation.description.images) || description.text !== beforeLocation.description.text
    const storiesChanged = 
      JSON.stringify(stories) !== JSON.stringify(beforeLocation.stories)
    navigationInfo !== stop.navigationInfo
    || name !== beforeLocation.name
    || geopoint?.latitude !== beforeLocation.geolocation.latitude
    || geopoint.longitude !== beforeLocation.geolocation.longitude
    || lastStoryImage !== beforeLocation.lastStoryImg
    || descriptionChanged
    || storiesChanged
      ? setDataChanged(true)
      : setDataChanged(false)
  }, [navigationInfo, description, name, geopoint, stories, lastStoryImage, beforeLocation, stop])

  useEffect(() => {
    if (isCityLocation(stop.location)) {
      setName(stop.location.name)
      setDescription(stop.location.description)
      setGeopoint(stop.location.geolocation)
      setStories((stop.location as ExtendedCityLocation).stories?.map((story: Story) => ({order: story.order, text: story.text, image: story.image})) ?? [])
      setLastStoryImage((stop.location as ExtendedCityLocation).lastStoryImg ?? '')
      setBeforeLocation({
        name: stop.location.name,
        description: stop.location.description,
        geolocation: stop.location.geolocation,
        id: stop.location.id,
        stories: (stop.location as ExtendedCityLocation).stories?.map((story: Story) => ({order: story.order, text: story.text, image: story.image})) ?? [],
        lastStoryImg: (stop.location as ExtendedCityLocation).lastStoryImg ?? ''
      })
    }
  }, [stop])

  window.onbeforeunload = () => {
    if (dataChanged)
      return "Are you sure you want to leave? You have an unsaved data";
  };

  return (
    <>
      <li className="mb-1">
        <Draggable draggableId={stop.id} index={idx}>
          {(draggableProvided) => 
            <div 
              {...draggableProvided.dragHandleProps}
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              className="is-flex is-align-items-center"
            >
              <div 
                className={`column is-flex is-justify-content-space-between is-align-items-center box my-0 mr-3
                  ${(highlightedStopId === stop.id) ? 'has-background-light' : ''}`}
                onClick={()=>handleActive(prev=>{
                  if (prev) {
                    return prev.includes(idx)?prev.filter((item) => item!==idx):[...prev, idx]
                  }
                  else return [idx]
                })}
              >
                {stop.id}
                <FontAwesomeIcon icon={faGripVertical} />
              </div>
              {!isDisabled && <RemoveButton handleClick={() => handleRemoveCurrentStop(stop.id)} />}
            </div>
          }
        </Draggable>
        {isActive && isActive.includes(idx) && (
          <div className="column box">
            {dataChanged && !isDisabled && (
              <SaveButton handleClick={handleUpdateStop}>
                Save Stop
              </SaveButton>
            )}
            <div className="column is-flex is-full px-1 py-0 mt-3">
              <Subtitle>Location</Subtitle>
            </div>
            <div className="column is-one-quarter px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
              <label>Location name</label>
              <TextInput 
                disabled={isDisabled}
                placeholder="location name"
                value={name}
                handleChange={(e) => setName(e.target.value)}
              />
            </div>
            {beforeLocation && <LocationMap 
              geolocation={beforeLocation?.geolocation} 
              handleChangeLocation={handleChangeLocation} 
              isDisabled={isDisabled} 
            />}
            {description && 
              <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                <label>Location description</label>
                <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                  <label>Images</label>
                  <div className="is-flex">
                    {description.images.map((img, idx) => (
                      <div key={idx} className='is-relative'>
                        <img 
                          src={img} 
                          style={{width: '150px', height: '150px'}} 
                          className="mr-2"
                          alt="img" 
                        />
                        {!isDisabled && 
                          <RemoveButton 
                            handleClick={() => setDescription({...description, images: description.images.filter((image) => image !== img)})} 
                            additionalStyle={{position: 'absolute', right: '10px', top: '0'}} 
                          />
                        }
                      </div>
                    ))}
                    {!isDisabled && <div className="file">
                      <label className="file-label">
                        <FileInput handleChange={async (e) => {
                          const url = await getUploadedImageUrl(e)
                          setDescription(description ? {...description, images: [...description.images, url]} : {images: [url], text: ''})
                        }}/>
                        <span className="file-cta is-flex is-justify-content-center" style={{width: '150px', height: '150px'}}>
                          <span className="file-label is-size-1">
                            +
                          </span>
                        </span>
                      </label>
                    </div>}
                  </div>
                </div>
                  <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                    <FlexContainer>
                      <label>Text</label>
                      <InfoButton handleClick={() => {
                        setShowFieldInfoModal(true)
                        setFieldToShow({limitation: 2500, description: 'Please, provide a description of current stop location', image: locationDescription})
                      }}/>
                    </FlexContainer>
                    <MDEditor
                      value={description.text}
                      onChange={(str) => setDescription({...description, text: str?.slice(0, 2500) ?? ''})}
                      previewOptions={{}}
                    />
                </div> 
              </div>
            }
            <div className="column is-one-quarter px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
              <FlexContainer>
                <label>Navigation Info</label>
                <InfoButton handleClick={() => {
                  setShowFieldInfoModal(true)
                  setFieldToShow({limitation: 100, description: 'Please, provide a navigation info of current scour stop location', image: locationNavigationInfo})
                }}/>
              </FlexContainer>
              <TextInput 
                disabled={isDisabled}
                placeholder="navigation info"
                value={navigationInfo ?? ''}
                handleChange={(e) => setNavigationInfo(e.target.value)}
              />
            </div>
            <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
              <label>Stories</label>
              <ul className="m-0">
                {stories?.map((story, index) => 
                  <li className="mb-1" key={index}>
                    <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                      <FlexContainer>
                        <label className="mr-3">Story {index + 1}</label>
                        {!isDisabled && 
                          <RemoveButton 
                            handleClick={() => {
                              let newStories = [...stories]
                              newStories.splice(index, 1)
                              setStories(newStories)
                            }}
                          />
                        }
                      </FlexContainer>
                      <div className="column is-one-quarter p-0 mt-3 mb-5 is-flex is-flex-direction-column">
                        <label>Image</label>
                        <div className="is-relative">
                          {story.image && <img src={story.image} alt='story-img' />}
                          {!isDisabled && <FileWrapper>
                            <label className="file-label">
                              <FileInput handleChange={async (e) => {
                                const url = await getUploadedImageUrl(e)
                                const newStories = [...stories]
                                newStories[index] = {...story, image: url}
                                setStories(newStories)
                              }}/>
                              <span className="file-cta">
                                <span className="file-label">
                                  {story.image ? 'Change Image' : 'Add Image'}
                                </span>
                              </span>
                            </label>
                          </FileWrapper>}
                        </div>
                      </div>
                      <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                        <label>Text</label>
                        <MDEditor
                          value={story.text}
                          onChange={(str) => {
                            const newStories = [...stories]
                            newStories[index] = {...story, text: str?.slice(0, 2500) ?? ''}
                            setStories(newStories)
                          }}
                          previewOptions={{}}
                        />
                      </div>
                      <div className="column is-one-fifth px-1 py-0 mt-3 mb-1 is-flex is-flex-direction-column">
                        <label>Order</label>
                        <input
                          type="number"
                          className="input"
                          disabled={isDisabled}
                          value={story.order}
                          max={99999}
                          onChange={(e) => {
                            const newStories = [...stories]
                            newStories[index] = {...story, order: Number(e.target.value)}
                            setStories(newStories)
                          }}
                        />
                      </div>
                    </div>
                  </li>
                )}
              </ul>
              <button
                disabled={isDisabled}
                onClick={() => {
                  setStories((prev) => prev ? [...prev, {order: stories.length + 1, text: 'Story text', image: ''}] : [{order: 1, text: 'Story text', image: ''}])
                }}
                className="button is-light mt-5 is-align-self-flex-start"
              >
                Add Story
              </button>
              <div className="column is-one-quarter p-0 mt-3 mb-5 is-flex is-flex-direction-column">
                <label>Last Story Image</label>
                <div className="is-relative">
                  {lastStoryImage && <img src={lastStoryImage} alt='story-img' />}
                  {!isDisabled && <FileWrapper>
                    <label className="file-label">
                      <FileInput handleChange={async (e) => {
                        const url = await getUploadedImageUrl(e)
                        setLastStoryImage(url)
                      }}/>
                      <span className="file-cta">
                        <span className="file-label">
                          {lastStoryImage ? 'Change Last Story Image' : 'Add Last Story Image'}
                        </span>
                      </span>
                    </label>
                  </FileWrapper>}
                </div>
              </div>
            </div>
            <div className="column is-flex is-full px-1 py-0 mt-3">
              <Subtitle>Challenges</Subtitle>
              <button onClick={()=>setIsChallengeActive([])} className="button ml-5">collapse all</button>
              <button onClick={()=>setIsChallengeActive(stop?.challenges.map((_, idx)=>idx))} className="button ml-5">expand all</button>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="popups">
                {(droppableProvided) => 
                  <ul
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    className="m-0"
                  >
                    {challenges?.map((challenge: any, idx) => (
                      <ChallengeItem 
                        key={challenge.id} 
                        challenge={challenge} 
                        idx={idx} 
                        isActive={isChallengeActive} 
                        handleActive={setIsChallengeActive} 
                        highlightedChallengeId={highlightedChallengeId}
                        isDisabled={isDisabled}
                        refetch={refetch}
                        type={'Stop'}
                        stopId={stop.id}
                      />
                    ))}
                    {droppableProvided.placeholder}
                  </ul>
                }
              </Droppable>
            </DragDropContext>
            <button
              disabled={isDisabled}
              onClick={() => {
                if (isDisabled) return
                handleAddChallenge(stop)
              }}
              className="button is-light mt-5"
            >
              Add Challenge
            </button>
          </div>
        )}
      </li>
      {showFieldInfoModal && fieldToShow && <FieldInfoModal fieldToShow={fieldToShow} handleModalClose={handleInfoModalClose} />}
    </>
  )
}

export default StopItem