import "firebase/auth";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { firebase } from "../services/firebase";
import { useNavigate } from "react-router-dom";
import { ALLOWED_ROLES } from "../context";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "../graphql/queries";
import { TextInput } from "../components/TextInput";
import { faGoogle, faApple } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SigninPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isApple, setIsApple] = useState(false)

  const navigate = useNavigate();

  const [getUser] = useLazyQuery(GET_USER);

  useEffect(() => {
    setError("");
  }, [email, password]);

  useEffect(() => {
    const appleRegex = /iPad|iPhone|iPod/
    appleRegex.test(navigator.userAgent) && setIsApple(true)
  }, [])

  const checkRoles = async () => {
    const userDoc = await getUser()
    userDoc.data?.me?.roles?.some((role: string) =>
      ALLOWED_ROLES.some((allowed) => allowed === role)
    )
      ? navigate("/")
      : alert(
        "Something went wrong. Check email, password and your permission!"
      );
  }

  const handleGoogleSignIn = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithPopup(provider)
      await checkRoles()
    } catch (error: any) {
      setError(error.message)
    }
  }

  const handleAppleSignIn = async () => {
    try {
      const provider = new firebase.auth.OAuthProvider('apple.com');
      await firebase.auth().signInWithPopup(provider)
      await checkRoles()
    } catch (error: any) {
      setError(error.message)
    }
  }

  const handleFormSubmit = async () => {
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      await checkRoles()
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <>
      <h1 className="has-text-centered is-size-3 mt-6 mb-0">SIGN IN</h1>
      <div className="column is-half mx-auto mt-6 is-flex is-flex-direction-column is-align-items-center">
        <div className="column is-full">
          <TextInput 
            marginRight={true}
            placeholder="email"
            value={email}
            handleChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div className="column is-full">
          <input
            className="input mr-3"
            type="password"
            placeholder='Password'
            value={password}
            onChange={(event: BaseSyntheticEvent) => setPassword(event.target.value)}
          />
        </div>
        {error && <p className="is-size-4 has-text-danger m-0">{error}</p>}
        <button 
          disabled={!email || !password} 
          onClick={handleFormSubmit} 
          className="button has-text-centered is-outlined is-medium px-4 py-3 m-1"
        >
          Sign In
        </button>
        <div className="column is-full is-flex is-justify-content-center">
          <button
            onClick={handleGoogleSignIn}
            className="button m-1"
          >
            <FontAwesomeIcon 
              icon={faGoogle}  
              className="has-text-danger"
            />
          </button>
          {isApple && <button
            onClick={handleAppleSignIn}
            className="button m-1"
          >
            <FontAwesomeIcon 
              icon={faApple}  
              className="has-text"
            />
          </button>}
        </div>
      </div>
    </>
  );
};

export default SigninPage;
