import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import SignInPage from "./pages/SignInPage";
import SignOutPage from "./pages/SignOutPage";
import AuthRoute from "./components/AuthRoute";
import UsersPage from "./pages/UsersPage";
import CityPage from "./pages/CityPage";
import ScourPage from "./pages/ScourPage";
import ArticlePage from "./pages/ArticlePage";
import AchievementPage from "./pages/AchievementPage";
import CouponPage from "./pages/CouponPage";
import UserPage from "./pages/UserPage";
import PartyPage from "./pages/PartyPage";

import "./App.css";

const App: React.FC = () => (
  <Routes>
    <Route path="/" element={<AuthRoute />}>
      <Route path="/" element={<HomePage />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/city/:cityId" element={<CityPage />}></Route>
      <Route path="/scour/:scourId" element={<ScourPage />}></Route>
      <Route path="/article/:articleId" element={<ArticlePage />}></Route>
      <Route path="/achievement/:achievementId" element={<AchievementPage />}></Route>
      <Route path="/coupon/:couponId" element={<CouponPage />}></Route>
      <Route path="/user/:userId" element={<UserPage />}></Route>
      <Route path="/user/:userId/party/:partyId/:scourId" element={<PartyPage />}></Route>
    </Route>
    <Route path="/signin" element={<SignInPage />} />
    <Route path="/signout" element={<SignOutPage />} />
  </Routes>
);

export default App;
