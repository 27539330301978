import {
  Ref,
  Article,
  Scour,
  CityLocation,
} from "@cityscour/types";
import {
  BaseSyntheticEvent,
  Dispatch,
  SetStateAction,
} from "react";
import { firebase } from "../services/firebase"
import Resizer from "react-image-file-resizer";

const resizeFile = (file: File) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const dataURIToBlob = (dataURI: any): Blob => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const handleScourClick = (
  scour: Scour,
  setTiles: Dispatch<SetStateAction<any[]>>
) => {
  setTiles((prev) => {
    if (prev) {
      if (prev.some((tile) => tile.id === scour.id))
        return prev.filter((tile) => tile.id !== scour.id);
      else {
        return [...prev, scour];
      }
    } else {
      return [scour];
    }
  });
};

export const handleArticleClick = (
  article: Article,
  setTiles: Dispatch<SetStateAction<any[]>>
) => {
  setTiles((prev) => {
    if (prev) {
      if (prev.some((tile) => tile.id === article.id))
        return prev.filter((tile) => tile.id !== article.id);
      else {
        return [...prev, article];
      }
    } else {
      return [article];
    }
  });
};

export function isCityLocation(
  location: Ref<CityLocation> | CityLocation
): location is CityLocation {
  return (location as CityLocation).name !== undefined;
}

export const getUploadedImageUrl = async (e: BaseSyntheticEvent) => {
  if (e.target.files.length === 0) return

  const storageRef = firebase.storage().ref()
  const file = e.target.files[0]

  if (file.type.includes('image')) {
    const name = `${Date.now()}-${file.name}`
    const imageRef = storageRef.child(name)
    const image: any = await resizeFile(file)
    const newFile = dataURIToBlob(image)

    await imageRef.put(newFile)

    const url = await storageRef
      .child(name)
      .getDownloadURL()
      .catch(() => alert('Something went wrong while uploading file'))

    return url
  }
  
  if (file.type.includes('video')) {
    const name = `${Date.now()}-${file.name}`
    const imageRef = storageRef.child(name)

    await imageRef.put(file)

    const url = await storageRef
      .child(name)
      .getDownloadURL()
      .catch(() => alert('Something went wrong while uploading file'))

    return url
  }
}

export const isVideo = (url: string): boolean => {
  const parts = url.split('.')
  const last = parts[parts.length - 1].split('?')[0]
  switch (last.toLowerCase()) {
    case 'mp4':
    case 'mov':
    case 'wmv':
    case 'avi':
    case 'webm':
    case 'mkv':
    case 'flv':
    case 'ogg':
      return true
  }
  return false
}