import { Article, Challenge } from "@cityscour/types"
import articleBody from './../images/article_body.png'
import { useContext, useEffect, useState } from "react"
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd"
import { useParams } from "react-router-dom"
import ChallengeItem from "../components/ChallengeItem"
import Loading from "../components/Loading"
import MDEditor from '@uiw/react-md-editor'
import UIContext from "../context"
import useInfoModal from "../hooks/useInfoModal"
import FieldInfoModal from "../components/FieldInfoModal"
import { useMutation, useQuery } from "@apollo/client"
import { GET_ARTICLE } from "../graphql/queries"
import { ADD_ARTICLE_CHALLENGE, UPDATE_ARTICLE, UPDATE_ARTICLE_CHALLENGE } from "../graphql/mutations"
import { PageHeader } from "../components/PageHeader"
import { PageTitle } from "../components/PageTitle"
import { ContentWrapper } from "../components/ContentWrapper"
import { RemoveButton } from "../components/RemoveButton"
import { InfoButton } from "../components/InfoButton"
import { SaveButton } from "../components/SaveButton"
import { TextInput } from "../components/TextInput"
import { getUploadedImageUrl } from "../functions/functions"
import { FileInput } from "../components/FileInput"
import { ModifyWarning } from "../components/ModifyWarning"
import { QuarterWrapper } from "../components/QuarterWrapper"
import { Subtitle } from "../components/Subtitle"
import { FlexContainer } from "../components/FlexContainer"

interface ChallengeWithId extends Challenge {
  id: string
}

const ArticlePage = () => {
  const { articleId } = useParams<string>();
  const { roles } = useContext(UIContext);

  const [article, setArticle] = useState<Article>()
  const [name, setName] = useState('')
  const [body, setBody] = useState('')
  const [images, setImages] = useState<string[]>()
  const [summary, setSummary] = useState<string[]>()
  const [challenges, setChallenges] = useState<ChallengeWithId[]>()
  const [hidden, setHidden] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const [isChallengeActive, setIsChallengeActive] = useState<number[]>()
  const [highlightedChallengeId, setHighlightedChallengeId] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  const { data, refetch } = useQuery(GET_ARTICLE, {
    variables: {
      articleId
    }
  })

  const [updateArticle] = useMutation(UPDATE_ARTICLE)
  const [addChallengeToArticle] = useMutation(ADD_ARTICLE_CHALLENGE)
  const [updateArticleChallenge] = useMutation(UPDATE_ARTICLE_CHALLENGE)
  
  const {showFieldInfoModal, setShowFieldInfoModal, fieldToShow, setFieldToShow, handleInfoModalClose} = useInfoModal()

  const handleUpdateArticle = async () => {
    if (!article || images === undefined || !name || !body) return;
    await updateArticle({
      variables: {
        input: {
          articleId: article.id,
          name,
          body,
          images,
          hidden,
          summary
        }
      }
    })
    await refetch()
  };

  const handleDragEnd = async (res: DropResult) => {
    if (!challenges || isDisabled) return
    const {destination, source} = res
    if (!destination) return
    if (destination.droppableId !== source.droppableId || destination.index === source.index) return

    let draggableChallenge = challenges[source.index]
    let temp = [...challenges]
    const element = temp.splice(temp.indexOf(draggableChallenge), 1)[0]
    temp.splice(destination.index, 0, element)
    setChallenges(temp)

    await updateArticleChallenge({
      variables: {
        input: {
          articleId,
          challengeId: challenges[source.index].id,
          challenge: {
            order: destination.index + 1,
            question: challenges[source.index].question
          }
        }
      }
    })
    await refetch()
  }

  useEffect(() => {
    setIsDisabled(!article?.hidden && !roles?.some((role) => role === 'admin' || role === 'internal'))
  }, [article, roles])

  useEffect(() => {
    if (!article) return;
    name !== article.name ||
    body !== article.body ||
    hidden !== article.hidden ||
    JSON.stringify(images) !== JSON.stringify(article.images) ||
    JSON.stringify(summary) !== JSON.stringify(article.summary)
      ? setDataChanged(true)
      : setDataChanged(false);
  }, [article, name, body, images, summary, hidden]);

  useEffect(() => {
    if (!data || !articleId) return;
    setArticle({
      ...data.article,
      challenges: data.article.challenges.map((ch: any) => ({
        ...ch,
        ...(ch.hint && { hint: {
          text: ch.hint.text,
          ...(ch.hint.image && { image: ch.hint.image })
        }}),
        ...(ch.navigation && { navigation: {
          ...(ch.navigation.name && { name: ch.navigation.name }),
          ...(ch.navigation.image && { image: ch.navigation.image }),
          ...(ch.navigation.geolocation && { geolocation: {
            latitude: ch.navigation.geolocation.latitude,
            longitude: ch.navigation.geolocation.longitude 
          }}),
        }}),
        answerSet: {
          kind: ch.answerSet.kind,
          ...(ch.answerSet.options && { options: ch.answerSet.options }),
          correct:
            (ch.answerSet.multipleCorrect && (Array.isArray(ch.answerSet.multipleCorrect) ? ch.answerSet.multipleCorrect.map((c: any) => c.toString()) : [ch.answerSet.multipleCorrec.toString()])) ??
            (ch.answerSet.numberCorrect && (Array.isArray(ch.answerSet.numberCorrect) ? ch.answerSet.numberCorrect.map((c: any) => c.toString()) : [ch.answerSet.numberCorrect.toString()])) ??
            ch.answerSet.stringCorrect,
        },
      }))
    });
    setName(data.article.name);
    setBody(data.article.body);
    setImages(data.article.images);
    setSummary(data.article.summary);
    setChallenges(
      data.article.challenges.map((ch: any) => ({
        ...ch,
        ...(ch.hint && { hint: {
          text: ch.hint.text,
          ...(ch.hint.image && { image: ch.hint.image })
        }}),
        ...(ch.navigation && { navigation: {
          ...(ch.navigation.name && { name: ch.navigation.name }),
          ...(ch.navigation.image && { image: ch.navigation.image }),
          ...(ch.navigation.geolocation && { geolocation: {
            latitude: ch.navigation.geolocation.latitude,
            longitude: ch.navigation.geolocation.longitude 
          }}),
        }}),
        answerSet: {
          kind: ch.answerSet.kind,
          ...(ch.answerSet.options && { options: ch.answerSet.options }),
          correct:
            (ch.answerSet.multipleCorrect && (Array.isArray(ch.answerSet.multipleCorrect) ? ch.answerSet.multipleCorrect.map((c: any) => c.toString()) : [ch.answerSet.multipleCorrec.toString()])) ??
            (ch.answerSet.numberCorrect && (Array.isArray(ch.answerSet.numberCorrect) ? ch.answerSet.numberCorrect.map((c: any) => c.toString()) : [ch.answerSet.numberCorrect.toString()])) ??
            ch.answerSet.stringCorrect,
        },
      }))
    );
    setHidden(data.article.hidden);
  }, [articleId, data]);

  window.onbeforeunload = () => {
    if (dataChanged)
      return "Are you sure you want to leave? You have an unsaved data";
  };

  if (!article || !roles) return <Loading />;

  return (
    <>
      <PageHeader />
      {isDisabled && <ModifyWarning />}
      {dataChanged && !isDisabled && (
        <SaveButton handleClick={handleUpdateArticle}>
          Save
        </SaveButton>
      )}
      <PageTitle>{article.name}</PageTitle>
      <QuarterWrapper>
        <label>Name</label>
        <TextInput 
          placeholder="article name"
          disabled={isDisabled}
          value={name}
          handleChange={(e) => setName(e.target.value)}
        />
      </QuarterWrapper>
      <ContentWrapper>
        <label>Images</label>
        <div className="is-flex">
          {images &&
            images.map((img, idx) => (
              <div key={idx} className="is-relative">
                <img
                  src={img}
                  style={{ width: "150px", height: "150px" }}
                  className="mr-2"
                  alt="img"
                />
                {!isDisabled && 
                  <RemoveButton 
                    handleClick={() => setImages(images.filter((image) => image !== img))}
                    additionalStyle={{ position: "absolute", right: "10px", top: "0" }}
                  />
                }
              </div>
            ))}
          {!isDisabled && <div className="file">
            <label className="file-label">
              <FileInput handleChange={async (e) => {
                const url = await getUploadedImageUrl(e)
                setImages(images ? [...images, url] : [url])
              }}/>
              <span
                className="file-cta is-flex is-justify-content-center"
                style={{ width: "150px", height: "150px" }}
              >
                <span className="file-label is-size-1">+</span>
              </span>
            </label>
          </div>}
        </div>
      </ContentWrapper>
      <ContentWrapper>
        <FlexContainer>
          <label>Text</label>
          <InfoButton handleClick={() => {
            setShowFieldInfoModal(true)
            setFieldToShow({limitation: 2500, description: 'Please, provide a description of current article', image: articleBody})
          }}/>
        </FlexContainer>
        <MDEditor
          value={body}
          onChange={(str) => setBody(str?.slice(0, 2500) ?? '')}
        />
      </ContentWrapper>
      {roles.some((role) => role === "admin" || role === "internal") && (
        <div className="column is-one-quarter px-5 py-0 mt-3 mb-5">
          <label className="is-flex is-align-items-center">
            Hidden
            <input
              type="checkbox"
              checked={hidden}
              className="ml-3"
              onChange={(e) => setHidden(e.target.checked)}
            />
          </label>
        </div>
      )}
      <div className="column is-flex is-full px-5 py-0 mt-3">
        <Subtitle>Summary</Subtitle>
      </div>
      <ul className="m-5">
        {summary &&
          summary.map((item, idx) => (
            <li
              className="column is-half is-flex is-align-items-center"
              key={idx}
            >
              <TextInput 
                marginRight={true}
                disabled={isDisabled}
                value={item}
                handleChange={(e) => {
                  const newSummary = [...summary];
                  newSummary[idx] = e.target.value;
                  setSummary(newSummary);
                }}
              />
              {!isDisabled && <RemoveButton handleClick={() => setSummary(summary.filter((sum) => sum !== item))} />}
            </li>
          ))}
        <button
          disabled={isDisabled}
          onClick={() =>
            setSummary(summary ? [...summary, "New summary"] : ["New Summary"])
          }
          className="button is-light mt-5 mr-5"
        >
          Add Summary
        </button>
      </ul>
      <div className="column is-flex is-full px-5 py-0 mt-3">
        <Subtitle>Challenges</Subtitle>
        {challenges && (
          <>
            <button
              onClick={() => setIsChallengeActive([])}
              className="button ml-5"
            >
              collapse all
            </button>
            <button
              onClick={() =>
                setIsChallengeActive(challenges.map((_, idx) => idx))
              }
              className="button ml-5"
            >
              expand all
            </button>
          </>
        )}
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="challenges">
          {(droppableProvided) => 
            <ul 
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              className="m-5"
            >
              {challenges?.map((challenge: any, idx) => (
                <ChallengeItem 
                  key={challenge.id} 
                  challenge={challenge} 
                  idx={idx} 
                  isActive={isChallengeActive} 
                  handleActive={setIsChallengeActive} 
                  highlightedChallengeId={highlightedChallengeId}
                  isDisabled={isDisabled}
                  refetch={refetch}
                  type={'Article'}
                />
              ))}
              {droppableProvided.placeholder}
            </ul>
          }
        </Droppable>
      </DragDropContext>
      <button
        disabled={isDisabled}
        onClick={() => {
          if (isDisabled) {
            return
          }
          addChallengeToArticle({
            variables: {
              input: {
                articleId: article.id,
                challenge: {
                  question: 'Challenge Question',
                  explanation: 'Challenge explanation',
                  answerSet: {
                    kind: 'FreeTextAnswerSet',
                    correct: 'answer',
                  },
                }
              }
            }
          }).then( async(res) => {
            await refetch()
            setHighlightedChallengeId(res.data.addArticleChallenge.id)
          })
        }}
        className="button is-light m-5"
      >
        Add Challenge
      </button>
      {fieldToShow && showFieldInfoModal && <FieldInfoModal handleModalClose={handleInfoModalClose} fieldToShow={fieldToShow} />}
    </>
  )
}

export default ArticlePage;
