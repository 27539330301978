import { Achievement } from "@cityscour/types"
import { useCallback, useEffect, useState } from "react"
import achievementDescription from './../images/achievements_description.png'
import { useParams } from "react-router-dom"
import ChangeScoursModal from "../components/ChangeScoursModal"
import Loading from "../components/Loading"
import { getUploadedImageUrl } from "../functions/functions"
import FieldInfoModal from "../components/FieldInfoModal"
import useInfoModal from "../hooks/useInfoModal"
import { useMutation, useQuery } from "@apollo/client"
import { GET_ACHIEVEMENT } from "../graphql/queries"
import { UPDATE_ACHIEVEMENT } from "../graphql/mutations"
import { PageHeader } from "../components/PageHeader"
import { PageTitle } from "../components/PageTitle"
import { ContentWrapper } from "../components/ContentWrapper"
import { InfoButton } from "../components/InfoButton"
import { SaveButton } from "../components/SaveButton"
import { TextInput } from "../components/TextInput"
import { FileInput } from "../components/FileInput"
import { TextArea } from "../components/TextArea"
import { QuarterWrapper } from "../components/QuarterWrapper"
import { Subtitle } from "../components/Subtitle"
import { FlexContainer } from "../components/FlexContainer"
import { FileWrapper } from "../components/FileWrapper"

const AchievementPage: React.FC = () => {
  const { achievementId } = useParams<string>()

  const [achievement, setAchievement] = useState<Achievement>()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [icon, setIcon] = useState('')
  const [scours, setScours] = useState<any[]>()
  const [dataChanged, setDataChanged] = useState(false)
  const [showChangeScoursModal, setShowChangeScoursModal] = useState(false)

  const { data, refetch } = useQuery(GET_ACHIEVEMENT, {
    variables: {
      achievementId
    }
  })

  const [updateAchievement] = useMutation(UPDATE_ACHIEVEMENT)

  const {fieldToShow, setFieldToShow, showFieldInfoModal, setShowFieldInfoModal, handleInfoModalClose} = useInfoModal()

  const handleChangeModalClose = useCallback(() => {
    setShowChangeScoursModal(false)
  }, [setShowChangeScoursModal])

  const handleFormSubmit = useCallback((tiles: any[]) => {
    setScours(tiles)
    setShowChangeScoursModal(false)
  }, [setScours, setShowChangeScoursModal])

  const handleUpdateAchievement = async () => {
    if (!achievement || !name || icon === undefined || !description || !scours) return
    await updateAchievement({
      variables: {
        input: {
          name,
          icon,
          description,
          achievementId: achievement.id,
          requiredScourIds: scours.map((sc) => sc.id)
        }
      }
    })
    await refetch()
  }

  useEffect(() => {
    if (!achievement) return;

    name !== achievement.name ||
    description !== achievement.description ||
    icon !== achievement.icon ||
    JSON.stringify(scours) !== JSON.stringify(achievement.requiredScours)
      ? setDataChanged(true)
      : setDataChanged(false);
  }, [achievement, name, description, icon, scours]);

  useEffect(() => {
    if (!data || !achievementId) return
    setAchievement(data.achievement)
    setName(data.achievement.name)
    setDescription(data.achievement.description)
    setIcon(data.achievement.icon)
    setScours(data.achievement.requiredScours)
  }, [achievementId, data])

  window.onbeforeunload = () => {
    if (dataChanged)
      return 'Are you sure you want to leave? You have an unsaved data'
  }

  if (!achievement || !scours) return <Loading />

  return (
    <>
      <PageHeader />
      {dataChanged && (
        <SaveButton handleClick={handleUpdateAchievement}>
          Save
        </SaveButton>
      )}
      <PageTitle>{achievement.name}</PageTitle>
      <QuarterWrapper>
        <label>Name</label>
        <TextInput 
          placeholder="Achievement name"
          value={name}
          handleChange={(e) => setName(e.target.value)}
        />
      </QuarterWrapper>
      <ContentWrapper>
        <FlexContainer>
          <label>Description</label>
          <InfoButton handleClick={() => {
            setShowFieldInfoModal(true)
            setFieldToShow({limitation: 100, description: 'Please, provide a description of current achievement', image: achievementDescription})
          }}/>
        </FlexContainer>
        <TextArea value={description} placeholder="achievement description" handleChange={(e) => setDescription(e.target.value)} />
      </ContentWrapper>
      <QuarterWrapper>
        <label>Image</label>
        <img src={icon} alt='icon' />
        <FileWrapper>
          <label className="file-label">
            <FileInput handleChange={async (e) => {
              const url = await getUploadedImageUrl(e)
              setIcon(url)
            }}/>
            <span className="file-cta">
              <span className="file-label">
                Change Image
              </span>
            </span>
          </label>
        </FileWrapper>
      </QuarterWrapper>
      <QuarterWrapper>
        <Subtitle>Required Scours</Subtitle>
      </QuarterWrapper>
      <ul className="m-5">
        {scours && scours.map((sc: any, idx) => (
          <li key={sc.id} className="is-flex">
            <span>{idx + 1}. {sc.name}</span>
          </li>
        ))}
        <button
          onClick={() => setShowChangeScoursModal(true)}
          className="button is-light mt-5"
        >
          Change Required Scours
        </button>
      </ul>
      {showChangeScoursModal && <ChangeScoursModal handleFormSubmit={handleFormSubmit} handleModalClose={handleChangeModalClose} previousScours={scours} />}
      {showFieldInfoModal && fieldToShow && <FieldInfoModal fieldToShow={fieldToShow} handleModalClose={handleInfoModalClose} />}
    </>
  )
}

export default AchievementPage