import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { FIREBASE } from "../config";

firebase.initializeApp(FIREBASE);

const firestore = firebase.firestore();

export { firebase, firestore };
