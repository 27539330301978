import React, { useEffect } from 'react'
import { Navigate } from 'react-router'
import { firebase } from "../services/firebase"

const SignOutPage: React.FC = () => {

  useEffect(() => {
    firebase.auth().signOut();
  }, [])

  return (
    <Navigate to="/signin" />
  )
}

export default SignOutPage
