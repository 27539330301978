import {
  OwnedScour,
  Scour,
  voidReturnFunction,
} from "@cityscour/types"
import { useState } from "react"

interface Props {
  handleModalClose: voidReturnFunction
  handleChangeApprove: (id: string, pinsAmount: number, redeemed: boolean) => void
  scourToChange: OwnedScour
}

const ChangeOwnedScourModal: React.FC<Props> = ({
  handleChangeApprove,
  handleModalClose,
  scourToChange,
}) => {
  const [pinsAmount, setPinsAmount] = useState(scourToChange.pinsAmount)
  const [redeemed, setRedeemed] = useState(scourToChange.redeemed)

  return (
    <>
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Change Owned Scour</p>
            <button
              onClick={handleModalClose}
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <div>
              <div className="mr-6 mb-6 is-flex is-flex-direction-column">
                <span className="my-1">Scour name: {(scourToChange?.scour as unknown as Scour)?.name}</span>
                <label className="is-flex is-align-items-center my-1">
                  <span className="is-flex-shrink-0">Redeemed: </span>
                  <input
                    type="checkbox"
                    checked={redeemed}
                    className="ml-3"
                    onChange={(e) => setRedeemed(e.target.checked)}
                  />
                </label>
                <label className="is-flex is-align-items-center my-1">
                  <span className="is-flex-shrink-0">Pins amount: </span>
                  <input
                    type="number"
                    className="input ml-3"
                    value={pinsAmount}
                    min={0}
                    max={99}
                    onChange={(e) => setPinsAmount(Number(e.target.value))}
                  />
                </label>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={() => {
                if (pinsAmount === scourToChange.pinsAmount && scourToChange.redeemed === redeemed) {
                  handleModalClose()
                  return
                }
                handleChangeApprove(scourToChange?.scour?.id ?? '', pinsAmount, redeemed)
              }}
              className="button is-success"
            >
              Submit
            </button>
            <button onClick={handleModalClose} className="button">
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </>
  )
}

export default ChangeOwnedScourModal