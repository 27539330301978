import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Loading from "./Loading";
import { firebase } from "../services/firebase";

export function useNullableUser(): [firebase.User | null, boolean] {
  const currentUser = firebase.auth().currentUser;
  const [user, setUser] = useState<firebase.User | null>(currentUser);
  const [loading, setLoading] = useState(!currentUser);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return [user, loading];
}

/**
 * Drop-in replacement for react-dom's Route component.
 * Redirects user to login page if the user is not authenticated
 */
const AuthRoute: React.FC = () => {
  const [user, userLoading] = useNullableUser();

  if (userLoading) return <Loading />;

  return user ? <Outlet /> : <Navigate to="/signin" />;
};

export default AuthRoute;
