import logo from './../images/City_Scour_Logo.png'

export const PageHeader = () => (
  <div className="column is-full is-flex is-justify-content-space-between is-align-items-center px-5 py-4 box">
    <a href="/">
      <img className="image is-32x32" src={logo} alt="logo" />
    </a>
    <a href="/signout">
      <h6 className="title is-6">Log out</h6>
    </a>
  </div>
)