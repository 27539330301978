import { GeoPoint, TextContent } from "@cityscour/types"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback, useEffect, useState } from "react"
import { getUploadedImageUrl } from "../functions/functions"
import { FileInput } from "./FileInput"
import { FlexContainer } from "./FlexContainer"
import LocationMap from "./LocationMap"
import { RemoveButton } from "./RemoveButton"
import { Subtitle } from "./Subtitle"
import { TextArea } from "./TextArea"
import { TextInput } from "./TextInput"

interface Props {
  idx: number
  popup: any
  isActive: number[] | undefined
  handleActive: React.Dispatch<React.SetStateAction<number[] | undefined>>
  handleChangePopup: (popup: any, dataChanged: boolean) => void
  handleRemovePopup: (id: string) => void
  highlightedPopupId?: string
  isDisabled: boolean
}

const PopupItem: React.FC<Props> = ({
  idx, 
  popup, 
  isActive, 
  handleActive, 
  handleChangePopup, 
  handleRemovePopup, 
  highlightedPopupId, 
  isDisabled
}) => {
  const [name, setName] = useState<string>(popup.name)
  const [radius, setRadius] = useState<number>(popup.radius)
  const [geolocation, setGeolocation] = useState<GeoPoint>(popup.geolocation)
  const [article, setArticle] = useState<TextContent>(popup.article)
  const [dataChanged, setDataChanged] = useState(false)

  const handleChangeLocation = useCallback((geolocation: GeoPoint, radius?: number) => {
    if (!radius || isDisabled) return
    setGeolocation(geolocation)
    setRadius(radius)
  }, [isDisabled])

  useEffect(() => {
    if (!article || !geolocation || !radius) return
    const articleChanged = 
      JSON.stringify(article.images) !== JSON.stringify(popup.article.images) || article.text !== popup.article.text
    name !== popup.name 
    || radius !== popup.radius 
    || geolocation.latitude !== popup.geolocation.latitude
    || geolocation.longitude !== popup.geolocation.longitude
    || articleChanged
      ? setDataChanged(true)
      : setDataChanged(false)
  }, [name, geolocation, radius, article, popup])

  useEffect(() => {
    if (!article || !geolocation || !radius) return
    handleChangePopup({
      name,
      article,
      geolocation,
      radius,
      id: popup.id
    }, dataChanged)
  }, [name, article, geolocation, radius, dataChanged, popup.id])

  return (
    <li className="mb-1">
      <FlexContainer>
        <div 
          className={`column is-flex is-justify-content-space-between is-align-items-center box my-0 mr-3 is-clickable
            ${(highlightedPopupId === popup.id) ? 'has-background-light' : ''}`}
          onClick={()=>handleActive(prev=>{
            if (prev) {
              return prev.includes(idx)?prev.filter((item) => item!==idx):[...prev, idx]
            }
            else return [idx]
          })}
        >
          {popup.name ?? `Popup ${idx + 1}`}
          <FontAwesomeIcon icon={faGripVertical} />
        </div>
        {!isDisabled && <RemoveButton handleClick={() => handleRemovePopup(popup.id)} />}
      </FlexContainer>
      {isActive && isActive.includes(idx) && (
        <div className="column box">
          <div className="column is-one-quarter px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
            <label>Name</label>
            <TextInput 
              placeholder="name"
              disabled={isDisabled}
              value={name}
              handleChange={(e) => setName(e.target.value)}
            />
          </div>
          <LocationMap 
            geolocation={popup.geolocation} 
            radius={popup.radius} 
            handleChangeLocation={handleChangeLocation} 
            isDisabled={isDisabled} 
          />
          <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
            <Subtitle>Article</Subtitle>
            {article && (
              <>
                <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                  <label>Images</label>
                  <div className="is-flex">
                    {article.images.map((img, idx) => (
                      <div key={idx} className='is-relative'>
                        <img 
                          src={img} 
                          style={{width: '150px', height: '150px'}} 
                          className="mr-2"
                          alt="img" 
                        />
                        {!isDisabled && 
                          <RemoveButton 
                            handleClick={() => setArticle({...article, images: article.images.filter((image) => image !== img)})} 
                            additionalStyle={{position: 'absolute', right: '10px', top: '0'}} 
                          />
                        }
                      </div>
                    ))}
                    {!isDisabled && <div className="file">
                      <label className="file-label">
                        <FileInput handleChange={async (e) => {
                          const url = await getUploadedImageUrl(e)
                          setArticle(article ? {...article, images: [...article.images, url]} : {images: [url], text: ''})
                        }}/>
                        <span className="file-cta is-flex is-justify-content-center" style={{width: '150px', height: '150px'}}>
                          <span className="file-label is-size-1">
                            +
                          </span>
                        </span>
                      </label>
                    </div>}
                  </div>
                </div>
                <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
                  <label>Text</label>
                  <TextArea placeholder="name" disabled={isDisabled} value={article.text} handleChange={(e) => setArticle({...article, text: e.target.value})}/>
                </div>
              </>     
            )}
          </div>
        </div>
      )}
    </li>
  )
}

export default PopupItem