import { Achievement, City, Coupon, voidReturnFunction } from "@cityscour/types";
import "bulma/css/bulma.min.css";

interface Props {
  handleModalClose: voidReturnFunction
  handleModalApprove: (item: City | Achievement | Coupon) => void
  itemToRemove: City | Achievement | Coupon
}

const RemoveItemModal: React.FC<Props> = ({handleModalApprove, handleModalClose, itemToRemove}) => {
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Are you sure you want to remove this item?</p>
        </header>
        <footer className="modal-card-foot">
          <button onClick={() => handleModalApprove(itemToRemove)} className="button is-success">Yes</button>
          <button onClick={handleModalClose} className="button">Cancel</button>
        </footer>
      </div>
    </div>
  )
}

export default RemoveItemModal