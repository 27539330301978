import {
    ApolloClient,
    from,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
  } from '@apollo/client'
  import { setContext } from '@apollo/client/link/context'
  import { onError } from '@apollo/client/link/error'
  import { firebase } from "./firebase"
  import { BACKEND_LINK } from '../config'
  
  export class apollo {
    private static errorLink = onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
          console.log(message)
        })
      }
    })
  
    private static link = from([
      this.errorLink,
      new HttpLink({ uri: BACKEND_LINK }),
    ])
  
    private static authLink = setContext(async (_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = await firebase.auth().currentUser?.getIdToken(true)
      const transactionId = Math.random().toString(36).substr(2, 9)
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
          'x-transaction-id': transactionId,
        },
      }
    })
  
    private static client = new ApolloClient({
      cache: new InMemoryCache(),
      link: this.authLink.concat(this.link),
    })
  
    static getClient(): ApolloClient<NormalizedCacheObject> {
      return this.client
    }
  }
  