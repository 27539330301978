import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UIProvider } from "./context";
import { ApolloProvider } from "@apollo/client";
import { apollo } from "./services/apollo";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apollo.getClient()}>
      <BrowserRouter>
        <UIProvider>
          <App />
        </UIProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
