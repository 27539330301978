import { gql } from '@apollo/client'

export const UPDATE_USER_ROLE = gql`
  mutation updateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      id
    }
  }
`

export const DELETE_SCOUR_COUPON = gql`
  mutation deleteScourCoupon($couponId: ID!) {
    deleteScourCoupon(couponId: $couponId)
  }
`

export const DELETE_CITY = gql`
  mutation deleteCity($cityId: ID!) {
    deleteCity(cityId: $cityId)
  }
`

export const DELETE_ACHIEVEMENT = gql`
  mutation deleteAchievement($achievementId: ID!) {
    deleteAchievement(achievementId: $achievementId)
  }
`

export const ADD_SCOUR_COUPON = gql`
  mutation addScourCoupon($input: AddScourCouponInput!) {
    addScourCoupon(input: $input) {
      id
    }
  }
`

export const ADD_ACHIEVEMENT = gql`
  mutation addAchievement($input: AddAchievementInput!) {
    addAchievement(input: $input) {
      id
    }
  }
`

export const ADD_CITY = gql`
  mutation addCity($input: AddCityInput!) {
    addCity(input: $input) {
      id
    }
  }
`

export const UPDATE_SCOUR_COUPON = gql`
  mutation updateCouponScours($input: UpdateCouponScoursInput!) {
    updateCouponScours(input: $input) {
      id
    }
  }
`

export const UPDATE_ACHIEVEMENT = gql`
  mutation updateAchievement($input: UpdateAchievementInput!) {
    updateAchievement(input: $input) {
      id
    }
  }
`

export const UPDATE_CITY = gql`
  mutation updateCity($input: UpdateCityInput!) {
    updateCity(input: $input) {
      id
    }
  }
`

export const ADD_SCOUR = gql`
  mutation addScour($input: AddScourInput!) {
    addScour(input: $input) {
      id
      name
      description {
        images: imageUrls
      }
    }
  }
`

export const ADD_ARTICLE = gql`
  mutation addArticle($input: AddArticleInput!) {
    addArticle(input: $input) {
      id
      name
      images: imageUrls
    }
  }
`

export const DELETE_SCOUR = gql`
  mutation deleteScour($scourId: ID!) {
    deleteScour(scourId: $scourId)
  }
`

export const DELETE_ARTICLE = gql`
  mutation deleteArticle($articleId: ID!) {
    deleteArticle(articleId: $articleId)
  }
`

export const UPDATE_SCOUR = gql`
  mutation updateScour($input: UpdateScourInput!) {
    updateScour(input: $input) {
      id
      popups {
        id
      }
    }
  }
`

export const UPDATE_ARTICLE = gql`
  mutation updateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      id
    }
  }
`

export const ADD_ARTICLE_CHALLENGE = gql`
  mutation addArticleChallenge($input: AddArticleChallengeInput!) {
    addArticleChallenge(input: $input) {
      id
    }
  }
`

export const DELETE_ARTICLE_CHALLENGE = gql`
  mutation deleteArticleChallenge($input: DeleteArticleChallengeInput!) {
    deleteArticleChallenge(input: $input)
  }
`

export const UPDATE_ARTICLE_CHALLENGE = gql`
  mutation updateArticleChallenge($input: UpdateArticleChallengeInput!) {
    updateArticleChallenge(input: $input) {
      id
    }
  }
`

export const ADD_SCOUR_STOP = gql`
  mutation addScourStop($input: AddScourStopInput!) {
    addScourStop(input: $input) {
      id
    }
  }
`

export const DELETE_SCOUR_STOP = gql`
  mutation deleteScourStop($input: DeleteScourStopInput!) {
    deleteScourStop(input: $input)
  }
`

export const UPDATE_SCOUR_STOP = gql`
  mutation updateScourStop($input: UpdateScourStopInput!) {
    updateScourStop(input: $input) {
      id
    }
  }
`

export const ADD_STOP_CHALLENGE = gql`
  mutation addStopChallenge($input: AddStopChallengeInput!) {
    addStopChallenge(input: $input) {
      id
    }
  }
`

export const DELETE_STOP_CHALLENGE = gql`
  mutation deleteStopChallenge($input: DeleteStopChallengeInput!) {
    deleteStopChallenge(input: $input)
  }
`

export const UPDATE_STOP_CHALLENGE = gql`
  mutation updateStopChallenge($input: UpdateStopChallengeInput!) {
    updateStopChallenge(input: $input) {
      id
    }
  }
`

export const ADD_OWNED_SCOUR = gql`
  mutation addOwnedScour($input: addOwnedScourInput!) {
    addOwnedScour(input: $input) {
      id
    }
  }
`

export const UPDATE_OWNED_SCOUR = gql`
  mutation updateOwnedScour($input: updateOwnedScourInput!) {
    updateOwnedScour(input: $input) {
      id
    }
  }
`

export const DELETE_OWNED_SCOUR = gql`
  mutation deleteOwnedScour($ownedScourId: ID!) {
    deleteOwnedScour(ownedScourId: $ownedScourId)
  }
`