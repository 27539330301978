import { BaseSyntheticEvent } from "react"

interface TextAreaProps {
  disabled?: boolean
  handleChange: (e: BaseSyntheticEvent) => void
  placeholder?: string
  value: string
}

export const TextArea = ({ disabled, handleChange, placeholder, value }: TextAreaProps) => (
  <textarea
    rows={10}
    disabled={disabled}
    onChange={handleChange}
    className="textarea"
    placeholder={placeholder}
    value={value}
    maxLength={100}
  />
)