import { voidReturnFunction } from "@cityscour/types";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface RemoveButtonProps {
  handleClick: voidReturnFunction
  additionalStyle?: any 
}

export const RemoveButton = ({handleClick, additionalStyle}: RemoveButtonProps) => (
  <FontAwesomeIcon
    onClick={handleClick}
    icon={faXmark}
    className='has-text-danger is-clickable'
    style={additionalStyle}
  />
)