import { gql } from '@apollo/client'

export const GET_CITIES = gql`
  query cities($includeHidden: Boolean) {
    cities(includeHidden: $includeHidden) {
      id
      name
      geofence {
        radius
        geolocation {
          latitude
          longitude
        }
      }
      tiles {
        ... on Article {
          id
        }
        ... on Scour {
          id
        }
      }
      hidden
    }
  }
`

export const GET_ACHIEVEMENTS = gql`
  query achievements {
    achievements {
      id
      name
    }
  }
`

export const GET_COUPONS = gql`
  query scourCoupons {
    scourCoupons {
      id
    }
  }
`

export const GET_USERS = gql`
  query users {
    users {
      id
      username
      roles
      email
      ownedScours {
        scour {
          id
          name
        }
        pinsAmount
        redeemed
      }
    }
  }
`

export const GET_SCOUR_COUPON = gql`
  query scourCoupon($couponId: ID!) {
    scourCoupon(couponId: $couponId) {
      id
      scours {
        name
        id
        description {
          images: imageUrls
        }
      }
      usageInfo {
        user {
          id
          username
        }
      }
    }
  }
`

export const GET_ACHIEVEMENT = gql`
  query achievement($achievementId: ID!) {
    achievement(achievementId: $achievementId) {
      id
      name
      icon: iconUrl
      description
      requiredScours {
        name
        id
        description {
          images: imageUrls
        }
      }
    }
  }
`

export const GET_SCOURS = gql`
  query scours($includeHidden: Boolean) {
    scours(includeHidden: $includeHidden) {
      name
      id
      description {
        images: imageUrls
      }
      hidden
    }
  }
`

export const GET_ARTICLES = gql`
  query articles {
    articles {
      id
      name
      images: imageUrls
      hidden
    }
  }
`

export const GET_CITY = gql`
  query city($cityId: ID!) {
    city(cityId: $cityId) {
      id
      tiles {
        ... on Article {
          id
          name
          images: imageUrls
          hidden
        }
        ... on Scour {
          name
          id
          description {
            images: imageUrls
          }
          hidden
        }
      }
      name
      geofence {
        radius
        geolocation {
          latitude
          longitude
        }
      }
      hidden
    }
  }
`

export const GET_SCOUR = gql`
  query scour($scourId: ID!) {
    scour(scourId: $scourId) {
      name
      description {
        text
        images: imageUrls
      }
      stops {
        id
        location {
          name
          description {
            images: imageUrls
            text
          }
          geolocation {
            latitude
            longitude
          }
          stories {
            image
            order
            text
          }
          lastStoryImg
        }
        challenges {
          id
          question
          explanation
          answerSet {
            ... on MultipleChoiceAnswerSet {
              options
              multipleCorrect: correct
              kind
            }
            ... on FreeTextAnswerSet {
              stringCorrect: correct
              kind
            }
            ... on FreeNumberAnswerSet {
              numberCorrect: correct
              kind
            }
          }
          image: imageUrl
          navigation {
            geolocation {
              longitude
              latitude
            }
            image: imageUrl
            name
          }
          hint {
            text
            image: imageUrl
          }
          order
        }
        navigationInfo
        order
      }
      popups {
        id
        name
        description {
          images: imageUrls
          text
        }
        geofence {
          radius
          geolocation {
            longitude
            latitude
          }
        }
      }
      duration
      level
      id
      additionalInfo
      tags
      beta
      hidden
    }
  }
`

export const GET_ARTICLE = gql`
  query article($articleId: ID!) {
    article(articleId: $articleId) {
      id
      name
      images: imageUrls
      body
      summary
      challenges {
        id
        question
        explanation
        answerSet {
          ... on MultipleChoiceAnswerSet {
            options
            multipleCorrect: correct
            kind
          }
          ... on FreeTextAnswerSet {
            kind
            stringCorrect: correct
          }
          ... on FreeNumberAnswerSet {
            numberCorrect: correct
            kind
          }
        }
        image: imageUrl
        navigation {
          name
          image: imageUrl
          geolocation {
            longitude
            latitude
          }
        }
        hint {
          text
          image: imageUrl
        }
        order
      }
      hidden
    }
  }
`

export const GET_USER = gql`
  query me {
    me {
      id
      roles
    }
  }
`

export const GET_PINS = gql`
  query pins {
    pins {
      id
      amount
      redeemed
      userId
      achievementId
    }
  }
`

export const GET_OWNED_SCOURS = gql`
  query usersOwnedScours($userId: ID!) {
    usersOwnedScours(userId: $userId) {
      id
      userId
      scourId
    }
  }
`

export const GET_PARTIES = gql`
  query allParties {
    allParties {
      id
      scour {
        name
        id
      }
      users {
        id
      }
    }
  }
`

export const GET_USER_LOCATIONS = gql`
  query userLocations {
    userLocations {
      id
      latitude
      longitude
      partyId
      timestamp
      userId
    }
  }
`