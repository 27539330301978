import { Ref, Scour, User, voidReturnFunction } from "@cityscour/types";
import "bulma/css/bulma.min.css";

interface Props {
  handleModalClose: voidReturnFunction
  handleRemoveApprove: (id: string) => void
  scourToRemove: Ref<Scour>
}

const RemoveOwnedScourModal: React.FC<Props> = ({handleRemoveApprove, handleModalClose, scourToRemove}) => {
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Are you sure you want to remove {(scourToRemove as unknown as Scour).name}?</p>
        </header>
        <footer className="modal-card-foot">
          <button onClick={() => handleRemoveApprove(scourToRemove.id)} className="button is-success">Yes</button>
          <button onClick={handleModalClose} className="button">Cancel</button>
        </footer>
      </div>
    </div>
  )
}

export default RemoveOwnedScourModal