import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChangeScoursModal from "../components/ChangeScoursModal";
import Loading from "../components/Loading";
import UIContext from './../context'
import { useMutation, useQuery } from "@apollo/client";
import { GET_SCOUR_COUPON } from "../graphql/queries";
import { UPDATE_SCOUR_COUPON } from "../graphql/mutations";
import { PageHeader } from "../components/PageHeader";
import { ContentWrapper } from "../components/ContentWrapper";
import { SaveButton } from "../components/SaveButton";
import { ModifyWarning } from "../components/ModifyWarning";
import { QuarterWrapper } from "../components/QuarterWrapper";
import { Subtitle } from "../components/Subtitle";

const CouponPage: React.FC = () => {
  const {roles} = useContext(UIContext)

  const { couponId } = useParams<string>();
  const [coupon, setCoupon] = useState<any>();
  const [scours, setScours] = useState<any[]>();
  const [dataChanged, setDataChanged] = useState(false);
  const [showChangeScoursModal, setShowChangeScoursModal] = useState(false);

  const { data, refetch } = useQuery(GET_SCOUR_COUPON, {
    variables: {
      couponId
    }
  })

  const [updateCoupon] = useMutation(UPDATE_SCOUR_COUPON)

  const handleModalClose = useCallback(() => {
    setShowChangeScoursModal(false);
  }, [setShowChangeScoursModal]);

  const handleFormSubmit = useCallback(
    (tiles: any[]) => {
      setScours(tiles);
      setShowChangeScoursModal(false);
    },
    []
  );

  const handleUpdateCoupon = async () => {
    if (!roles?.some((role) => role === "internal" || role === "admin")) {
      alert("You are not allowed to change this document!");
      return;
    }
    if (!coupon || !couponId || !scours) return;
    await updateCoupon({
      variables: {
        input: {
          couponId,
          scourIds: scours.map((sc) => sc.id)
        }
      }
    })
    await refetch()
  };

  useEffect(() => {
    if (!coupon) return;
    JSON.stringify(scours) !== JSON.stringify(coupon.scours)
      ? setDataChanged(true)
      : setDataChanged(false);
  }, [coupon, scours]);

  useEffect(() => {
    if (!couponId || !data) return;
    setCoupon(data.scourCoupon)
    setScours(data.scourCoupon.scours)
  }, [couponId, data]);

  window.onbeforeunload = () => {
    if (dataChanged)
      return "Are you sure you want to leave? You have an unsaved data";
  }

  if (!coupon || !roles) return <Loading />;

  return (
    <>
      <PageHeader />
      {!roles.some((role) => role === 'admin' || role === 'internal') && (
        <ModifyWarning />
      )}
      {dataChanged && roles.some((role) => role === 'admin' || role === 'internal') && (
        <SaveButton handleClick={handleUpdateCoupon}>
          Save
        </SaveButton>
      )}
      <div
        style={{ alignItems: "baseline" }}
        className="column is-full px-5 py-0 mt-5 is-flex"
      >
        <h1 className="title m-0 is-1 has-text-weight-bold">{couponId}</h1>
        <label className="is-flex is-align-items-center">
          : used {coupon.usageInfo.length} times
        </label>
      </div>

      <QuarterWrapper>
        <Subtitle>Free Scours</Subtitle>
      </QuarterWrapper>
      <ul className="m-5">
        {scours &&
          scours.map((sc: any, idx) => (
            <li key={sc.id} className="is-flex">
              <span>{idx + 1}. {sc.name}</span>
            </li>
          ))}
        <button
          onClick={() => setShowChangeScoursModal(true)}
          className="button is-light mt-5"
          disabled={!roles.some((role) => role === 'admin' || role === 'internal')}
        >
          Change Free Scours
        </button>
      </ul>
      {coupon.usageInfo.length > 0 && (
        <ContentWrapper>
          <Subtitle>
            This coupon was used by:
          </Subtitle>
          <ul className="">
            {coupon.usageInfo.map((u: any) => (
              <li>
                <div className="column p-0">
                  <label>{u.user.username}</label>
                </div>
              </li>
            ))}
          </ul>
        </ContentWrapper>
      )}
      {showChangeScoursModal && (
        <ChangeScoursModal
          handleFormSubmit={handleFormSubmit}
          handleModalClose={handleModalClose}
          previousScours={scours || []}
        />
      )}
    </>
  );
};

export default CouponPage;
