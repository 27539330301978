import { City, GeoPoint, Tile as ITile } from "@cityscour/types";
import "bulma/css/bulma.min.css";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChangeTilesModal from "../components/ChangeTilesModal";
import Loading from "../components/Loading";
import LocationMap from "../components/LocationMap";
import RemoveTileModal from "../components/RemoveTileModal";
import Tile from "../components/Tile";
import UIContext from "./../context";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CITY } from "../graphql/queries";
import { ADD_ARTICLE, ADD_SCOUR, DELETE_ARTICLE, DELETE_SCOUR, UPDATE_CITY } from "../graphql/mutations";
import { PageHeader } from "../components/PageHeader";
import { PageTitle } from "../components/PageTitle";
import { SaveButton } from "../components/SaveButton";
import { TextInput } from "../components/TextInput";
import { ModifyWarning } from "../components/ModifyWarning";
import { QuarterWrapper } from "../components/QuarterWrapper";

const CityPage = () => {
  const { cityId } = useParams<string>();
  const { roles } = useContext(UIContext);

  const [city, setCity] = useState<City>()
  const [name, setName] = useState('')
  const [geofence, setGeofence] = useState<{geolocation: GeoPoint, radius: number}>()
  const [tiles, setTiles] = useState<any[]>()
  const [hidden, setHidden] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const [showChangeTilesModal, setShowChangeTilesModal] = useState(false)
  const [highlightedTileId, setHighlightedTileId] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [tileToRemove, setTileToRemove] = useState<ITile>()
  const [showRemoveTileModal, setShowRemoveTileModal] = useState(false)

  const { data, refetch } = useQuery(GET_CITY, {
    variables: {
      cityId
    }
  })

  const [updateCity] = useMutation(UPDATE_CITY)
  const [addScour] = useMutation(ADD_SCOUR)
  const [addArticle] = useMutation(ADD_ARTICLE)
  const [deleteScour] = useMutation(DELETE_SCOUR)
  const [deleteArticle] = useMutation(DELETE_ARTICLE)

  const handleAddTilesModal = () => {
    setShowChangeTilesModal(true);
  };

  const handleUpdateCity = async () => {
    if (!city || !name || !geofence || !tiles) return;
    await updateCity({
      variables: {
        input: {
          name,
          cityId: city.id,
          radius: geofence.radius,
          geoPoint: {
            latitude: geofence.geolocation.latitude,
            longitude: geofence.geolocation.longitude,
          },
          scourIds: tiles.filter((t: any) => t.__typename.includes('Scour')).map((t) => t.id),
          articleIds: tiles.filter((t: any) => t.__typename.includes('Article')).map((t) => t.id),
          hidden
        }
      }
    })
    await refetch()
  };

  const handleRemoveTile = useCallback((tile: ITile) => {
    setTileToRemove(tile)
    setShowRemoveTileModal(true)
  }, [])

  const handleChangeModalClose = useCallback(() => {
    setShowChangeTilesModal(false);
  }, [setShowChangeTilesModal]);

  const handleRemoveModalClose = useCallback(() => {
    setShowRemoveTileModal(false)
  }, [setShowRemoveTileModal])

  const handleRemoveModalApprove = useCallback(async (tile: any) => {
    tile.__typename.includes('Scour')
      ? await deleteScour({
        variables: {
          scourId: tile.id
        }
      })
      : await deleteArticle({
        variables: {
          articleId: tile.id
        }
      })
    setTiles((prev) => prev?.filter((t) => t.id !== tile.id))
    await refetch()
    setShowRemoveTileModal(false)
  }, [])

  const handleFormSubmit = useCallback(
    (tiles: any[]) => {
      setTiles(tiles);
      setShowChangeTilesModal(false);
    },
    []
  );

  const handleChangeLocation = useCallback(
    (geolocation: GeoPoint, radius?: number) => {
      if (!radius || isDisabled) return;
      setGeofence({ geolocation, radius });
    },
    [setGeofence, isDisabled]
  );

  useEffect(() => {
    setIsDisabled(!city?.hidden && !roles?.some((role) => role === 'admin' || role === 'internal'))
  }, [city, roles])

  window.onbeforeunload = () => {
    if (dataChanged)
      return "Are you sure you want to leave? You have an unsaved data";
  };

  useEffect(() => {
    if (!city || !geofence) return;

    name !== city.name ||
    hidden !== city.hidden ||
    geofence.radius !== city.geofence.radius ||
    geofence.geolocation.longitude !== city.geofence.geolocation.longitude ||
    geofence.geolocation.latitude !== city.geofence.geolocation.latitude ||
    JSON.stringify(tiles) !== JSON.stringify(city.tiles)   
      ? setDataChanged(true)
      : setDataChanged(false);
  }, [name, city, hidden, tiles, geofence]);

  useEffect(() => {
    if (!data || !cityId) return;
    setCity(data.city);
    setName(data.city.name);
    setHidden(data.city.hidden);
    setGeofence(data.city.geofence);
    setTiles(data.city.tiles);
  }, [cityId, data]);

  if (!city || !tiles || !geofence || !roles) return <Loading />;

  return (
    <>
      <PageHeader />
      {isDisabled && <ModifyWarning />}
      {dataChanged && !isDisabled && (
        <SaveButton handleClick={handleUpdateCity}>
          Save
        </SaveButton>
      )}
      <PageTitle>{city.name}</PageTitle>
      <QuarterWrapper>
        <label>Name</label>
        <TextInput 
          placeholder="city name"
          value={name}
          disabled={isDisabled}
          handleChange={(e) => setName(e.target.value)}
        />
      </QuarterWrapper>
      <LocationMap
        geolocation={city.geofence.geolocation}
        radius={city.geofence.radius}
        handleChangeLocation={handleChangeLocation}
        isDisabled={isDisabled}
      />
      {roles.some((role) => role === "admin" || role === "internal") && (
        <div className="column is-one-quarter px-5 py-0 mt-3 mb-5">
          <label className="is-flex is-align-items-center">
            Hidden
            <input
              type="checkbox"
              checked={hidden}
              className="ml-3"
              onChange={(e) => setHidden(e.target.checked)}
            />
          </label>
        </div>
      )}
      <h4 className="title is-4 mt-6 mb-3 px-5">Tiles</h4>
      <div className="column is-full-mobile is-full-tablet is-half-desktop px-5 is-flex is-flex-wrap-wrap">
        {tiles.map((tile) => (
          <Tile tileRef={tile} key={tile.id} highlightedTileId={highlightedTileId} handleRemoveTile={!isDisabled ? handleRemoveTile : undefined} />
        ))}
      </div>
      <div className='column is-full px-5 py-0 mt-3 mb-3'>
        <button
          className="button is-light mx-3"
          disabled={isDisabled}
          onClick={() => {
            addScour({
              variables: {
                input: {
                  name: 'New Scour',
                  description: {text: 'Description', images: []},
                  stops: [],
                  popups: [],
                  duration: 60,
                  level: 'EASY',
                  tags: [],
                  beta: true,
                  hidden: true
                }
              }
            }).then((res) => {
              setTiles((prev) => prev ? [...prev, res.data.addScour] : [res.data.addScour])
              setHighlightedTileId(res.data.addScour.id)
            })
          }}
        >
          Add Scour
        </button>
        <button
          className="button is-light mx-3"
          disabled={isDisabled}
          onClick={() => {
            addArticle({
              variables: {
                input: {
                  name: 'New Article',
                  images: [],
                  body: 'Body',
                  hidden: true,
                }
              }
            }).then((res) => {
              setTiles((prev) => prev ? [...prev, res.data.addArticle] : [res.data.addArticle])
              setHighlightedTileId(res.data.addArticle.id)
            })
          }}
        >
          Add Article
        </button>
        <button
          className="button is-light mx-3"
          disabled={isDisabled}
          onClick={handleAddTilesModal}
        >
          Change tiles
        </button>
      </div>
      {showChangeTilesModal && (
        <ChangeTilesModal
          handleModalClose={handleChangeModalClose}
          handleFormSubmit={handleFormSubmit}
          previousTiles={tiles}
        />
      )}
      {showRemoveTileModal && tileToRemove && (
        <RemoveTileModal 
          handleModalApprove={handleRemoveModalApprove} 
          handleModalClose={handleRemoveModalClose} 
          tileToRemove={tileToRemove}
        />
      )}
    </>
  );
};

export default CityPage;
