import { voidReturnFunction } from "@cityscour/types"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface InfoButtonProps {
  handleClick: voidReturnFunction
}

export const InfoButton = ({ handleClick }: InfoButtonProps) => (
  <FontAwesomeIcon
    icon={faInfoCircle}
    className='is-clickable ml-1'
    onClick={handleClick}
  />
)