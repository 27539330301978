import { voidReturnFunction } from "@cityscour/types";

interface FieldToShow {
  limitation: number
  description: string
  image: string
}

interface Props {
  fieldToShow: FieldToShow
  handleModalClose: voidReturnFunction
}

const FieldInfoModal: React.FC<Props> = ({
  fieldToShow,
  handleModalClose
}) => (
  <>
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Field Info</p>
          <button 
            onClick={handleModalClose}
            className="delete" 
            aria-label="close">
          </button>
        </header>
        <section className="modal-card-body">
          <div>
            <div className="is-flex is-flex-direction-column">
              <p className="my-1">The character count limit is {fieldToShow.limitation} symbols.</p>
              <p className="my-1">{fieldToShow.description}</p>
              <img src={fieldToShow.image} alt="app-screenshot" className="image my-1" />
            </div>
          </div>
        </section>
      </div>
    </div>
  </>
);

export default FieldInfoModal;
