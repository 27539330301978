import { useCallback, useState } from "react"

interface FieldToShow {
  limitation: number
  description: string
  image: string
}

const useInfoModal = () => {
  const [showFieldInfoModal, setShowFieldInfoModal] = useState(false)
  const [fieldToShow, setFieldToShow] = useState<FieldToShow | null>(null)

  const handleInfoModalClose = useCallback(() => {
    setShowFieldInfoModal(false)
    setFieldToShow(null)
  }, [setShowFieldInfoModal, setFieldToShow])

  return {
    showFieldInfoModal,
    setShowFieldInfoModal,
    fieldToShow,
    setFieldToShow,
    handleInfoModalClose
  }
}

export default useInfoModal