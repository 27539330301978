import { useState } from "react";
import {
  Scour,
  voidReturnFunction,
} from "@cityscour/types";
import {
  handleScourClick,
} from "../functions/functions";
import { useQuery } from "@apollo/client";
import { GET_SCOURS } from "../graphql/queries";

interface Props {
  handleModalClose: voidReturnFunction;
  handleFormSubmit: (scours: any[]) => void;
  previousScours: any[]
}

const ChangeScoursModal: React.FC<Props> = ({
  handleFormSubmit,
  handleModalClose,
  previousScours
}) => {
  const [tiles, setTiles] = useState<any[]>(previousScours);

  const { data: allScours } = useQuery(GET_SCOURS, {
    variables: {
      includeHidden: true
    }
  })

  return (
    <>
      <div className="modal is-active">
        <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Choose scours from the list</p>
              <button 
                onClick={handleModalClose}
                className="delete" 
                aria-label="close"></button>
            </header>
            <section className="modal-card-body">
              <div>
                <div className="is-flex is-justify-content-space-between">
                  <div>
                    <h4 className="title is-4 my-2">Scours:</h4>
                    <ul>
                      {allScours?.scours.map((scour: Scour) => (
                        <li
                          style={{
                            background: `${tiles?.some((tile) => tile.id === scour.id) ? 'hsl(204, 86%, 53%)' : ''}`
                          }}
                          className="is-full p-3 box"
                          onClick={() => handleScourClick(scour, setTiles)}
                          key={scour.id}
                        >
                          <p>{scour.name}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <footer className="modal-card-foot">
              <button
                onClick={() => handleFormSubmit(tiles)}
                className="button is-success"
              >
                Submit
              </button>
              <button 
                onClick={handleModalClose}
                className="button"
              >
                Cancel
              </button>
            </footer>
          </div>
        </div>
    </>
  );
};

export default ChangeScoursModal;
