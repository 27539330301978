function getEnv(key: string): string {
  const val = process.env[key];
  if (!val) throw new Error(`Environment variable '${key}' must be set`);
  return val;
}

export const FIREBASE = {
  apiKey: getEnv("REACT_APP_FIREBASE_API_KEY"),
  authDomain: getEnv("REACT_APP_FIREBASE_AUTH_DOMAIN"),
  projectId: getEnv("REACT_APP_FIREBASE_PROJECT_ID"),
  storageBucket: getEnv("REACT_APP_FIREBASE_STORAGE_BUCKET"),
  messagingSenderId: getEnv("REACT_APP_FIREBASE_MESSAGING_SENDER_ID"),
  appId: getEnv("REACT_APP_FIREBASE_APP_ID"),
  measurementId: getEnv("REACT_APP_FIREBASE_MEASUREMENT_ID"),
};

export const BACKEND_LINK = getEnv('REACT_APP_BACKEND_LINK')
