import { Tile as ITile } from "@cityscour/types";
import { useContext, useEffect, useState } from "react";
import UIContext from "../context";
import { RemoveButton } from "./RemoveButton";

interface Props {
  highlightedTileId?: string
  tileRef: any
  handleRemoveTile?: (tile: ITile) => void
}

const Tile: React.FC<Props> = ({ tileRef, highlightedTileId, handleRemoveTile }) => {
  const {roles} = useContext(UIContext)
  const isArticle = tileRef.__typename.includes("Article");
  const pathName = isArticle ? `article/${tileRef.id}` : `scour/${tileRef.id}`;

  const [image, setImage] = useState("");

  useEffect(() => {
    isArticle ? setImage(tileRef.images[0]) : setImage(tileRef.description.images[0])
  }, [tileRef, isArticle]);

  return (
    <div
      className={`column ${
        isArticle
          ? "is-half-mobile is-half-tablet is-half-desktop"
          : "is-full-mobile is-full-tablet is-full-desktop"
      }`}
    >
      <div className={`card ${(tileRef?.id === highlightedTileId) ? 'has-background-light' : ''}`}>
        <div className="card-image">
          <figure className="image">
            <img
              className="image"
              src={image}
              alt="Tile"
              style={{ height: "250px", objectFit: "cover" }}
            />
          </figure>
        </div>
        <div className="card-content box">
          <div className="is-flex is-align-items-center is-justify-content-space-between is-align-items-center">
            <a
              href={`/${pathName}`}
              className="is-link is-align-self-flex-start p-0 is-underlined"
            >
              {tileRef?.name}
            </a>
            {handleRemoveTile && tileRef && roles && (tileRef.hidden || roles.some((role) => role === 'admin' || role === 'internal')) && 
              <RemoveButton handleClick={() => handleRemoveTile(tileRef)} />
            }
          </div>
        </div>
      </div>
    </div>
  )
};

export default Tile;
