import { User } from "@cityscour/types";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Loading from "../components/Loading";
import { ALLOWED_ROLES } from "../context";
import "bulma/css/bulma.min.css";
import UIContext from "../context";
import { useContext } from "react";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "../graphql/queries";
import { PageHeader } from "../components/PageHeader";
import { PageTitle } from "../components/PageTitle";
import { TextInput } from "../components/TextInput";

const UsersPage: React.FC = () => {
  const {roles} = useContext(UIContext)
  const [users, setUsers] = useState<User[]>()
  const [filteredUsers, setFilteredUsers] = useState<User[]>()
  const [searchText, setSearchText] = useState('')

  const { data } = useQuery(GET_USERS)

  useEffect(() => {
    if (!data) return
    setUsers(data.users)
    setSearchText('')
  }, [data])

  useEffect(() => {
    setFilteredUsers(users?.filter((user) => user.username.toLowerCase().includes(searchText.toLowerCase()) || user.email.toLowerCase().includes(searchText.toLowerCase())))
  }, [searchText, users])
  
  if (!roles || !users || !filteredUsers)
    return <Loading />

  if (!roles.some((role) => role === 'admin'))
    return <Navigate to={'/'}/>
    
  return (
    <>
     <PageHeader />
     <PageTitle>Users</PageTitle>
      <div className="column is-half px-5 py-0 my-5">
        <nav className="panel">
          <p className="panel-heading">
            Users search
          </p>
          <div className="panel-block">
            <p className="control">
              <TextInput 
                marginRight={true}
                placeholder="search"
                value={searchText}
                handleChange={(e) => setSearchText(e.target.value)}
              />
            </p>
          </div>
          <div className="is-flex is-flex-direction-column is-align-items-flex-start">
            {filteredUsers
              .filter((user) => !user.roles.some((role) => ALLOWED_ROLES.some((allowed) => role === allowed)))
              .map((user) => (
                <a
                  key={user.id}
                  href={`user/${user.id}`}
                  className="is-link p-2 mx-3 my-1 is-underlined has-background-light"
                >
                  username: {user.username}{user.email && `, email: ${user.email}`}
                </a>
              ))
            }
          </div>
        </nav>
      </div>
    </>
  );
};

export default UsersPage;
