import { Tile, voidReturnFunction } from "@cityscour/types";
import "bulma/css/bulma.min.css";

interface Props {
  handleModalClose: voidReturnFunction
  handleModalApprove: (tile: Tile) => void
  tileToRemove: Tile
}

const RemoveTileModal: React.FC<Props> = ({handleModalApprove, handleModalClose, tileToRemove}) => {
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Are you sure you want to remove {tileToRemove.name}?</p>
        </header>
        <footer className="modal-card-foot">
          <button onClick={() => handleModalApprove(tileToRemove)} className="button is-success">Yes</button>
          <button onClick={handleModalClose} className="button">Cancel</button>
        </footer>
      </div>
    </div>
  )
}

export default RemoveTileModal