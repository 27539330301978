import { useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import mapboxgl, { Map } from "mapbox-gl";
import { useParams } from "react-router-dom";
import { GET_SCOUR, GET_USER_LOCATIONS } from "../graphql/queries";
import { CityLocation, Scour, Stop } from "@cityscour/types";
import Loading from "../components/Loading";
import { PageHeader } from "../components/PageHeader";

interface UserLocation {
  id: string
  latitude: number
  longitude: number
  partyId: string
  timestamp: string
  userId: string
}

const mapStyles = {
  width: "100%",
  height: "100%",
};
  
const MAPBOX_STYLE = process.env["REACT_APP_MAPBOX_STYLE"];

const PartyPage: React.FC = () => {
  const { userId, partyId, scourId } = useParams<string>()

  const { data: allUserLocations } = useQuery(GET_USER_LOCATIONS)
  const { data: scourData } = useQuery(GET_SCOUR, {
    variables: {
      scourId
    }
  })

  const [currentTimeFrame, setCurrentTimeFrame] = useState(1)
  const [mapboxMap, setMapboxMap] = useState<Map>()

  const currentScour: Scour | undefined | null = useMemo(() => scourData?.scour, [scourData])
  const userLocations: UserLocation[] | undefined | null = useMemo(
    () => allUserLocations?.userLocations
      ?.filter((ul: UserLocation) => 
        ul.partyId === partyId && ul.userId === userId
      )
      ?.sort((ul1: UserLocation, ul2: UserLocation) =>
        new Date(ul1.timestamp).getTime() - new Date(ul2.timestamp).getTime()
      ),
    [allUserLocations, partyId, userId]
  )
  const timeFrameArray = useMemo(() => {
    if (!userLocations || userLocations.length === 0) return []
    let arr: number[] = []
    const timeFrameAmount = Math.ceil((new Date(userLocations[userLocations.length - 1].timestamp).getTime() - new Date(userLocations[0].timestamp).getTime()) / 60000)
    for (let i = 0; i < timeFrameAmount; i++) {
      arr.push(i + 1)
    }
    return arr
  }, [userLocations])

  const mapNode = useRef(null);

  mapboxgl.accessToken = process.env["REACT_APP_MAPBOX_TOKEN"] || "";

  const getTimeFromUserLocations = useCallback((frame: number) => {
    if (!userLocations) return '00:00:00'
    return new Date(new Date(userLocations[0].timestamp).getTime() + 60000 * (frame - 1)).toLocaleTimeString()
  }, [userLocations])

  useEffect(() => {
    if (!mapboxMap || !currentScour) return

    document.querySelectorAll('.mapboxgl-marker')?.forEach((el) => el.remove())

    currentScour.stops.forEach((stop: Stop) => {
      const popupSpan = document.createElement('span')
      popupSpan.textContent = `${(stop.location as CityLocation).name}`
      popupSpan.classList.add('mapboxgl-custom-popup')
      new mapboxgl.Marker()
        .setLngLat([(stop.location as CityLocation).geolocation.longitude, (stop.location as CityLocation).geolocation.latitude])
        .setPopup(
          new mapboxgl.Popup({
            offset: 30,
            closeOnMove: true,
          }).setDOMContent(popupSpan))
        .addTo(mapboxMap)
    })

    userLocations
      ?.filter((ul: UserLocation) => 
        currentTimeFrame === 1
          ? new Date(ul.timestamp).getTime() < new Date(userLocations[0].timestamp).getTime() + 60000
          : new Date(ul.timestamp).getTime() < new Date(userLocations[0].timestamp).getTime() + currentTimeFrame * 60000 &&
            new Date(ul.timestamp).getTime() > new Date(userLocations[0].timestamp).getTime() + (currentTimeFrame - 1) * 60000
      )
      ?.forEach((ul: UserLocation) => {
        new mapboxgl.Marker({
          color: '#F09835',
        })
          .setLngLat([ul.longitude, ul.latitude])
          .addTo(mapboxMap)
      })
  }, [mapboxMap, currentTimeFrame, userLocations, currentScour])

  useEffect(() => {
    const node = mapNode.current;

    if (
      typeof window === "undefined" ||
      node === null ||
      !currentScour
    ) {
      return;
    }      

    const currentMap = new mapboxgl.Map({
      style: MAPBOX_STYLE,
      bounds: [
        (currentScour.stops[0].location as CityLocation).geolocation.longitude,
        (currentScour.stops[0].location as CityLocation).geolocation.latitude,
        (userLocations && userLocations[0]?.longitude) ?? (currentScour.stops[currentScour.stops.length - 1].location as CityLocation).geolocation.longitude,
        (userLocations && userLocations[0]?.latitude) ?? (currentScour.stops[currentScour.stops.length - 1].location as CityLocation).geolocation.latitude,
      ],
      zoom: 10,
      container: node,
    });

    setMapboxMap(currentMap)

    return () => {
      currentMap.remove();
    };
  }, [currentScour, userLocations]);

  if (!currentScour || !userLocations)
    return <Loading />

  return (
    <>
      <PageHeader />
      <h4 className="title is-4 mt-3 mb-3 px-5">
        {userLocations.length > 0 
          ? 'User Locations During Party Play' 
          : 'There are no geolocation records of the user during this party play'
        }
      </h4>
      {timeFrameArray.length > 0 && <div className="px-5 is-flex is-align-items-center">
        <span className="mr-2">Chronological one-minute time frames of user's geolocation:</span>
        <div className="is-flex is-flex-direction-column">
          <input style={{width: '300px'}} type="range" min={1} max={timeFrameArray.length} step="1" value={currentTimeFrame} onChange={(e) => setCurrentTimeFrame(+e.target.value)} />
          <div className="is-flex is-justify-content-space-between">
            {timeFrameArray.map((frame) => <span>{getTimeFromUserLocations(frame)}</span>)}
          </div>
        </div>
      </div>}
      <div className="column is-full mt-3 mb-3 mx-0 px-5">
        <div
          className="column is-full p-0"
          style={{
            height: "600px",
            overflow: "hidden",
          }}
        >
          <div ref={mapNode} style={mapStyles} />
        </div>
      </div>
    </>
  )
  
}

export default PartyPage;