import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import "bulma/css/bulma.min.css";
import UIContext from "../context";
import { useContext } from "react";
import { PageHeader } from "../components/PageHeader";
import { PageTitle } from "../components/PageTitle";
import { OwnedScour, Ref, Scour, User, Achievement, Party } from "@cityscour/types";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ACHIEVEMENTS, GET_OWNED_SCOURS, GET_PARTIES, GET_PINS, GET_USERS } from "../graphql/queries";
import { ADD_OWNED_SCOUR, DELETE_OWNED_SCOUR, UPDATE_OWNED_SCOUR, UPDATE_USER_ROLE } from "../graphql/mutations";
import RemoveOwnedScourModal from "../components/RemoveOwnedScourModal";
import ChangeOwnedScourModal from "../components/ChangeOwnedScourModal";
import AddOwnedScourModal from "../components/AddOwnedScourModal";
import { useNullableUser } from "../components/AuthRoute";
import { BlockWrapper } from "../components/BlockWrapper";

const UserPage: React.FC = () => {
  const { userId } = useParams<string>()

  const [firebaseUser] = useNullableUser()

  const [user, setUser] = useState<User>()
  const [showModal, setShowModal] = useState(false)
  const [scourToRemove, setScourToRemove] = useState<Ref<Scour>>()
  const [scourToChange, setScourToChange] = useState<OwnedScour>()

  const {roles} = useContext(UIContext)

  const { data: users, refetch: updateUsers } = useQuery(GET_USERS)
  const { data: allOwnedScours, refetch: updateAllOwnedScours } = useQuery(GET_OWNED_SCOURS, {
    variables: {
      userId
    }
  })
  const { data: allPins, refetch: updateAllPins } = useQuery(GET_PINS)
  const { data: allAchievements } = useQuery(GET_ACHIEVEMENTS)
  const { data: allParties } = useQuery(GET_PARTIES)

  const [updateUserRole] = useMutation(UPDATE_USER_ROLE)
  const [addOwnedScour] = useMutation(ADD_OWNED_SCOUR)
  const [updateOwnedScour] = useMutation(UPDATE_OWNED_SCOUR)
  const [deleteOwnedScour] = useMutation(DELETE_OWNED_SCOUR)

  const handleUserRoleUpdate = async (id: string, roles: Array<string>) => {
    await updateUserRole({
      variables: {
        input: {
          userId: id,
          roles
        }
      }
    })
    await updateUsers()
  }

  const handleModalClose = useCallback(() => {
    setShowModal(false)
    setScourToChange(undefined)
    setScourToRemove(undefined)
  }, [])

  const handleRemoveModalApprove = useCallback((id: string) => {
    if (!allOwnedScours) return
    const ownedScour = allOwnedScours.usersOwnedScours?.find((oS: any) => oS.scourId === id && oS.userId === userId)
    if (!ownedScour) return
    deleteOwnedScour({
      variables: {
        ownedScourId: ownedScour.id
      }
    }).then(() => {
      updateUsers()
      updateAllOwnedScours()
      updateAllPins()
    })
    setScourToRemove(undefined)
    setShowModal(false)
  }, [allOwnedScours, userId])

  const handleChangeModalApprove = useCallback((id: string, pinsAmount: number, redeemed: boolean) => {
    if (!allOwnedScours || !allPins || !allAchievements) return
    const ownedScour = allOwnedScours.usersOwnedScours?.find((oS: any) => oS.scourId === id && oS.userId === userId)
    const achievementId = allAchievements.find((a: Achievement) => a.requiredScours.length === 1 && a.requiredScours.find((rs) => rs.id === id))?.id
    const pin = allPins.pins?.find((p: any) => p.achievementId === achievementId && p.userId === userId)
    if (!ownedScour) return
    updateOwnedScour({
      variables: {
        input: {
          ownedScourId: ownedScour.id,
          scourId: id,
          pinsAmount,
          redeemed,
          pinsData: pin ? [{
            id: pin.id,
            amount: pinsAmount,
            redeemed
          }] : []
        }
      }
    }).then(() => {
      updateUsers()
      updateAllOwnedScours()
      updateAllPins()
    })
    setScourToChange(undefined)
    setShowModal(false)
  }, [allOwnedScours, allPins, allAchievements, userId])

  const handleAddModalApprove = useCallback((id: string, pinsAmount: number) => {
    addOwnedScour({
      variables: {
        input: {
          scourId: id,
          pinsAmount,
          userId
        }
      }
    }).then(() => {
      updateUsers()
      updateAllOwnedScours()
      updateAllPins()
    })
    setShowModal(false)
  }, [userId])

  useEffect(() => {
    if (!users || !userId) return
    const user = users.users.find((u: User) => u.id === userId)
    setUser(user)
  }, [users, userId])
  
  if (!roles || !user || !allOwnedScours || !allPins || !allAchievements || !allParties)
    return <Loading />

  if (!roles.some((role) => role === 'admin'))
    return <Navigate to={'/'}/>
    
  return (
    <>
      <PageHeader />
      <PageTitle>{user.username}</PageTitle>
      <div className="column is-full px-5 py-0 mt-5">
        <div className="is-flex is-align-items-center my-5">
          <span>Email: {user.email}</span>
        </div>
        <div className="is-flex is-align-items-center my-5">
          <span>Joined at: {firebaseUser?.metadata.creationTime}</span>
        </div>
        <div className="is-flex is-align-items-center my-5">
          <span>Admin Role: </span>
          <div className="select mx-5">
            <select
              onChange={(e) => {
                handleUserRoleUpdate(user.id ?? '', e.target.value === '' ? [] : [e.target.value])
              }}
              style={{ width: "100%" }} 
              value={user.roles[0] ?? ''}
            >
              <option value="">null</option>
              <option value="content_partner">content_partner</option>
              <option value="internal">internal</option>
              <option value="admin">admin</option>
            </select>
          </div>
        </div>
      </div>
      <h4 className="title is-4 mt-6 mb-3 px-5">Owned Scours</h4>
      <div className="column is-full px-5 is-flex is-flex-wrap-wrap">
        {user.ownedScours?.map((oS) => (
          <div key={oS.scour?.id} className="mr-6 mb-6 is-flex is-flex-direction-column box">
            <span className="my-1">Scour name: {(oS?.scour as unknown as Scour)?.name}</span>
            <span className="my-1">Redeemed: {oS.redeemed ? 'true' : 'false'}</span>
            <span className="my-1">Pins amount: {oS.pinsAmount}</span>
            <div className="is-flex is-justify-content-space-between my-1">
              <button
                className="button is-light"
                onClick={() => {
                  setScourToChange(oS)
                  setShowModal(true)
                }}
              >
                Change
              </button>
              <button
                className="button is-light"
                onClick={() => {
                  setScourToRemove(oS?.scour)
                  setShowModal(true)
                }}
              >
                Remove
              </button>
            </div>
          </div>
        ))}
      </div>
      <button
        className="button is-light mx-5"
        onClick={() => {
          setShowModal(true)
        }}
      >
        Add owned scour
      </button>
      <h4 className="title is-4 mt-6 mb-3 px-5">Parties</h4>
      <BlockWrapper>
        <ul>
          {allParties?.allParties?.filter((party: Party) => party.users.find((u) => u.id === userId)).map((party: Party) => (
            <li 
              key={party.id} 
              className={`column box is-flex is-align-items-center is-justify-content-space-between px-3 py-1 my-3`}
            >
              <div className="is-flex is-flex-direction-column">
                <a
                  href={`${userId}/party/${party?.id}/${party.scour.id}`}
                  className="is-link is-align-self-flex-start p-0 is-underlined"
                >
                  {(party.scour as unknown as Scour).name}
                </a>
                <span>
                  {party.id}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </BlockWrapper>

      {showModal && scourToRemove && <RemoveOwnedScourModal handleRemoveApprove={handleRemoveModalApprove} handleModalClose={handleModalClose} scourToRemove={scourToRemove} />}
      {showModal && scourToChange && <ChangeOwnedScourModal handleChangeApprove={handleChangeModalApprove} handleModalClose={handleModalClose} scourToChange={scourToChange} />}
      {showModal && !scourToRemove && !scourToChange && <AddOwnedScourModal handleAddApprove={handleAddModalApprove} handleModalClose={handleModalClose} ownedScours={user.ownedScours} /> }
    </>
  );
};

export default UserPage;