import { BaseSyntheticEvent } from "react"

interface TextInputProps {
  handleChange: (event: BaseSyntheticEvent) => void
  value: string
  disabled?: boolean
  placeholder?: string
  marginRight?: boolean
}

export const TextInput = ({ handleChange, value, disabled, placeholder, marginRight }: TextInputProps) => (
  <input
    className={`input ${marginRight && 'mr-3'}`}
    type="text"
    placeholder={placeholder}
    disabled={disabled}
    value={value}
    onChange={handleChange}
    maxLength={100}
  />
)