import React, { useCallback, useContext, useEffect, useState } from "react";
import { Achievement, City, Coupon } from "@cityscour/types";
import "bulma/css/bulma.min.css";
import Loading from "../components/Loading";
import UIContext from "../context";
import RemoveItemModal from "../components/RemoveItemModal";
import { GET_ACHIEVEMENTS, GET_CITIES, GET_COUPONS } from "../graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_ACHIEVEMENT, ADD_CITY, ADD_SCOUR_COUPON, DELETE_ACHIEVEMENT, DELETE_CITY, DELETE_SCOUR_COUPON } from "../graphql/mutations";
import { PageHeader } from "../components/PageHeader";
import { PageTitle } from "../components/PageTitle";
import { BlockWrapper } from "../components/BlockWrapper";
import { RemoveButton } from "../components/RemoveButton";

const HomePage: React.FC = () => {
  const {roles} = useContext(UIContext)
  const [cities, setCities] = useState<City[] | null>(null)
  const [achievements, setAchievements] = useState<Achievement[] | null>(null)
  const [coupons, setCoupons] = useState<Coupon[]>();
  const [highlightedCityId, setHighlightedCityId] = useState('')
  const [highlightedAchievementId, setHighlightedAchievementId] = useState('')
  const [highlightedCouponId, setHighlightedCouponId] = useState('');
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [itemToRemove, setItemToRemove] = useState<City | Achievement | Coupon>()

  const { data: allCities, refetch: updateCities } = useQuery(GET_CITIES, {
    variables: {
      includeHidden: true
    }
  })
  const { data: allAchievements, refetch: updateAchievements } = useQuery(GET_ACHIEVEMENTS)
  const { data: allCoupons, refetch: updateCoupons } = useQuery(GET_COUPONS)

  const [deleteScourCoupon] = useMutation(DELETE_SCOUR_COUPON)
  const [deleteCity] = useMutation(DELETE_CITY)
  const [deleteAchievement] = useMutation(DELETE_ACHIEVEMENT)
  const [addScourCoupon] = useMutation(ADD_SCOUR_COUPON)
  const [addAchievement] = useMutation(ADD_ACHIEVEMENT)
  const [addCity] = useMutation(ADD_CITY)

  const handleAddCity = () => {
    addCity({
      variables: {
        input: {
          name: 'New City',
          radius: 50,
          geoPoint: {
            longitude: 30,
            latitude: 30
          },
          scourIds: [],
          articleIds: [],
          hidden: true
        }
      }
    }).then(async (res) => {
      await updateCities()
      setHighlightedCityId(res.data.addCity.id)
    })
  }

  const handleAddAchievement = () => {
    addAchievement({
      variables: {
        input: {
          name: 'New Achievement',
          description: 'Achievement Description',
          icon: '',
          requiredScourIds: [],
        }
      }
    }).then(async (res) => {
      await updateAchievements()
      setHighlightedAchievementId(res.data.addAchievement.id)
    })
  }

  const handleAddCoupon = async () => {
    if (!roles || !roles.some((role) => role === "admin" || role === "internal")) {
      return
    }
    addScourCoupon({
      variables: {
        input: {
          scourIds: []
        }
      }
    }).then(async (res) => {
      await updateCoupons()
      setHighlightedCouponId(res.data.addScourCoupon.id)
    })
  }

  const handleRemoveModalApprove = useCallback(async (item: any) => {
    if (item.__typename.includes('City')) {
      await deleteCity({
        variables: {
          cityId: item.id
        }
      })
      await updateCities()
    }

    if (item.__typename.includes('Achievement')) {
      await deleteAchievement({
        variables: {
          achievementId: item.id
        }
      })
      await updateAchievements()
    }

    if (item.__typename.includes('Coupon')) {
      await deleteScourCoupon({
        variables: {
          couponId: item.id
        }
      })
      await updateCoupons()
    }

    setShowRemoveModal(false)
  }, [setShowRemoveModal])

  const handleRemoveModalClose = useCallback(() => {
    setShowRemoveModal(false)
  }, [setShowRemoveModal])

  useEffect(() => {
    if (!allCities) return;
    setCities(allCities.cities);
  }, [allCities]);

  useEffect(() => {
    if (!allAchievements) return;
    setAchievements(allAchievements.achievements);
  }, [allAchievements]);

  useEffect(() => {
    if (!allCoupons) return;
    setCoupons(allCoupons.scourCoupons);
  }, [allCoupons]);

  if (!cities || !achievements || !coupons || !roles) return <Loading />;

  return (
    <>
      <PageHeader />
      <PageTitle>City</PageTitle>
      <BlockWrapper>
        <ul>
          {cities.map((city) => (
            <li 
              key={city.id} 
              className={`column box is-flex is-align-items-center is-justify-content-space-between px-3 py-1 my-3
              ${(city.id === highlightedCityId) ? 'has-background-light' : ''}`}
            >
              <div className="is-flex is-flex-direction-column">
                <a
                  href={`city/${city?.id}`}
                  className="is-link is-align-self-flex-start p-0 is-underlined"
                >
                  {city.name}
                </a>
                <span>
                  {`
                    ${city.tiles.filter((tile: any) => tile.__typename.includes("Scour"))
                      .length
                  }
                  scours`}
                </span>
              </div>
              {roles && (city.hidden || roles.some((role) => role === 'admin' || role === 'internal')) && 
                <RemoveButton handleClick={() => {
                  setItemToRemove(city)
                  setShowRemoveModal(true)
                }}/>
              }
            </li>
          ))}
        </ul>
      </BlockWrapper>
      <BlockWrapper>
        <button
          onClick={handleAddCity}
          className="button is-light"
        >
          Add City
        </button>
      </BlockWrapper>
      
      <PageTitle>Achievements</PageTitle>
      <BlockWrapper>
        <ul>
          {achievements?.map((ach) => (
            <li 
              key={ach.id} 
              className={`column box is-flex is-align-items-center is-justify-content-space-between px-3 py-1 my-3
              ${(ach.id === highlightedAchievementId) ? 'has-background-light' : ''}`}
            >
              <a
                href={`achievement/${ach?.id}`}
                className="is-link is-align-self-flex-start p-0 is-underlined"
              >
                {ach.name}
              </a>
              <RemoveButton handleClick={() => {
                setItemToRemove(ach)
                setShowRemoveModal(true)
              }}/>
            </li>
          ))}
        </ul>
      </BlockWrapper>
      <BlockWrapper>
        <button
          className="button is-light"
          onClick={handleAddAchievement}
        >
          Add Achievement
        </button>
      </BlockWrapper>

      <PageTitle>Coupons</PageTitle>
      <BlockWrapper>
        <ul>
          {coupons?.map((coupon) => (
            <li
              key={coupon.id}
              className={`column box is-flex is-align-items-center is-justify-content-space-between px-3 py-1 my-3
                ${(coupon.id === highlightedCouponId) ? 'has-background-light' : ''}`}
            >
              <a
                href={`coupon/${coupon.id}`}
                className="is-link is-align-self-flex-start p-0 is-underlined"
              >
                {coupon.id}
              </a>
              {roles.some((role) => role === 'admin' || role === 'internal') && 
                <RemoveButton handleClick={() => {
                  setItemToRemove(coupon)
                  setShowRemoveModal(true)
                }}/>
              }
            </li>
          ))}
        </ul>
      </BlockWrapper>
      <BlockWrapper>
        <button
          className="button is-light"
          onClick={handleAddCoupon}
          disabled={!roles.some((role) => role === 'admin' || role === 'internal')}
        >
          Add Coupon
        </button>
      </BlockWrapper>

      {roles.some((role) => role === "admin") &&
        <>
          <PageTitle>Users</PageTitle>
          <div className="column is-full px-5 py-0 mt-5">
            <a
              href={`users`}
              className="is-link is-align-self-flex-start p-0 is-underlined"
            >
              Modiy Users
            </a>
          </div>
        </>
      }

      {showRemoveModal && itemToRemove && <RemoveItemModal handleModalApprove={handleRemoveModalApprove} handleModalClose={handleRemoveModalClose} itemToRemove={itemToRemove} />}
    </>
  );
};

export default HomePage;