import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNullableUser } from "../components/AuthRoute";
import { GET_USER } from "../graphql/queries";

const useRoles = () => {
  const [user] = useNullableUser()
  const [getUser] = useLazyQuery(GET_USER)
  const [roles, setRoles] = useState<string[]>();

  useEffect(() => {
    if (!user) return
    getUser().then((res) => setRoles(res.data?.me?.roles ?? []))
  }, [user]);

  return roles
}

export default useRoles