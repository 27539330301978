import React from "react";

interface Props {
  classname?: string;
}

const Loading: React.FC<Props> = () => {
  return (
    <p className="has-text-centered is-size-4 mt-6">Please wait, loading in progress...</p>
  );
};

export default Loading;
