import { GeoPoint } from "@cityscour/types";
import mapboxgl, { MapMouseEvent, Marker } from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import circle from '@turf/circle';
import { useEffect, useRef, useState } from "react";

interface Props {
  geolocation: GeoPoint;
  radius?: number;
  handleChangeLocation: (geolocation: GeoPoint, radius?: number) => void
  isDisabled: boolean;
}

const mapStyles = {
  width: "100%",
  height: "100%",
};

const MAPBOX_STYLE = process.env["REACT_APP_MAPBOX_STYLE"];

const LocationMap: React.FC<Props> = ({ geolocation, radius, handleChangeLocation, isDisabled }) => {
  const mapNode = useRef(null);

  mapboxgl.accessToken = process.env["REACT_APP_MAPBOX_TOKEN"] || "";

  const [marker, setMarker] = useState<Marker>();
  const [currentLng, setCurrentLng] = useState(geolocation.longitude);
  const [currentLat, setCurrentLat] = useState(geolocation.latitude);
  const [currentRadius, setCurrentRadius] = useState(radius);

  const handleMapClick = (e: MapMouseEvent) => {
    if (isDisabled) return
    setCurrentLat(e.lngLat.lat)
    setCurrentLng(e.lngLat.lng)
  }

  useEffect(() => {
    handleChangeLocation({longitude: currentLng, latitude: currentLat}, currentRadius)
  }, [currentLng, currentLat, currentRadius, handleChangeLocation])

  useEffect(() => {
    const node = mapNode.current;

    if (
      typeof window === "undefined" ||
      node === null
    ) {
      return;
    }

    const currentMap = new mapboxgl.Map({
      style: MAPBOX_STYLE,
      center: [currentLng, currentLat],
      zoom: 10,
      container: node,
    });

    if (!currentMap) return;

    setMarker(
      new mapboxgl.Marker({
        draggable: !isDisabled && true,
      })
        .setLngLat([currentLng, currentLat])
        .addTo(currentMap)
    );

    if (currentRadius) {
      const radiusCircle = circle(
        {geometry: {type: 'Point', coordinates: [currentLng, currentLat]}, type: 'Feature', properties: {name: 'Circle'}},
        currentRadius, 
        10, 
        'kilometers'
      )

      currentMap.on('load', () => {
        currentMap.addSource('circleData', {
          type: 'geojson',
          data: radiusCircle
        })
    
        currentMap.addLayer({
          id: "circle-fill",
          type: "fill",
          source: "circleData",
          paint: {
            "fill-color": "yellow",
            "fill-opacity": 0.2
          }
        });

        currentMap.addControl(
          new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: currentMap
          })
        )
      })
    }
    
    currentMap.on('click', handleMapClick)

    return () => {
      currentMap.remove();
    };
  }, [isDisabled, currentLat, currentLng, currentRadius]);

  useEffect(() => {
    if (!marker || isDisabled) return;
    marker.on("dragend", () => {
      setCurrentLng(marker.getLngLat().lng);
      setCurrentLat(marker.getLngLat().lat);
    });
    marker.setLngLat([currentLng, currentLat]);
  }, [marker, currentLng, currentLat, isDisabled]);

  return (
    <div className="column is-one-quarter m-5 p-0">
      <label>Longitude</label>
      <input
        type="number"
        className="input my-1"
        value={currentLng}
        disabled={isDisabled}
        onChange={(e) => setCurrentLng(Number(e.target.value))}
      />
      <label>Latitude</label>
      <input
        type="number"
        className="input my-1"
        value={currentLat}
        disabled={isDisabled}
        onChange={(e) => setCurrentLat(Number(e.target.value))}
      />
      
      {currentRadius !== undefined && 
        <>
          <label>Radius</label>
          <input
            type="number" 
            className="input my-1" 
            value={currentRadius}
            disabled={isDisabled}
            onChange={(e) => setCurrentRadius(Number(e.target.value))}
          />
        </>
      }
      <div
        className="column is-full p-0"
        style={{
          height: "300px",
          overflow: "hidden",
        }}
      >
        <div ref={mapNode} style={mapStyles} />
      </div>
    </div>
  );
};

export default LocationMap;
