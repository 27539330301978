import {
  City,
  GeoPoint,
  Level,
  Scour,
  Stop,
  TextContent,
} from "@cityscour/types";
import scourInfo from './../images/scour_info.png';
import scourAdditionalInfo from './../images/scour_additional_info.png';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import MDEditor from '@uiw/react-md-editor';
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import PopupItem from "../components/PopupItem";
import StopItem from "../components/StopItem";
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import UIContext from "./../context";
import useInfoModal from "../hooks/useInfoModal";
import FieldInfoModal from "../components/FieldInfoModal";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CITIES, GET_SCOUR } from "../graphql/queries";
import { ADD_SCOUR_STOP, UPDATE_SCOUR, UPDATE_SCOUR_STOP } from "../graphql/mutations";
import { PageHeader } from "../components/PageHeader";
import { PageTitle } from "../components/PageTitle";
import { ContentWrapper } from "../components/ContentWrapper";
import { RemoveButton } from "../components/RemoveButton";
import { InfoButton } from "../components/InfoButton";
import { SaveButton } from "../components/SaveButton";
import { TextInput } from "../components/TextInput";
import { getUploadedImageUrl, isVideo } from "../functions/functions";
import { FileInput } from "../components/FileInput";
import { TextArea } from "../components/TextArea";
import { ModifyWarning } from "../components/ModifyWarning";
import { QuarterWrapper } from "../components/QuarterWrapper";
import { Subtitle } from "../components/Subtitle";
import { FlexContainer } from "../components/FlexContainer";

const ScourPage = () => {
  const { scourId } = useParams<string>();
  const { roles } = useContext(UIContext);

  const [scour, setScour] = useState<Scour>();
  const [name, setName] = useState('');
  const [description, setDescription] = useState<TextContent>();
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [duration, setDuration] = useState(0);
  const [level, setLevel] = useState<Level>();
  const [beta, setBeta] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [tags, setTags] = useState<string[]>();
  const [stops, setStops] = useState<Stop[]>([]);
  const [popups, setPopups] = useState<any[]>([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [isStopActive, setIsStopActive] = useState<number[]>();
  const [isPopupActive, setIsPopupActive] = useState<number[]>();
  const [highlightedStopId, setHighlightedStopId] = useState('')
  const [highlightedPopupId, setHighlightedPopupId] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [currentGeolocation, setCurrentGeolocation] = useState<GeoPoint>()

  const { data, refetch } = useQuery(GET_SCOUR, {
    variables: {
      scourId
    }
  })
  const { data: allCities } = useQuery(GET_CITIES)

  const [updateScour] = useMutation(UPDATE_SCOUR)
  const [addStop] = useMutation(ADD_SCOUR_STOP)
  const [updateStop] = useMutation(UPDATE_SCOUR_STOP)

  const {fieldToShow, setFieldToShow, showFieldInfoModal, setShowFieldInfoModal, handleInfoModalClose} = useInfoModal()

  const handleUpdateScour = async () => {
    if (
      !scour ||
      !name ||
      !description ||
      !duration ||
      !popups ||
      !level
    )
      return;
    await updateScour({
      variables: {
        input: {
          name,
          scourId: scour.id,
          description,
          additionalInfo,
          duration,
          level,
          beta,
          hidden,
          tags,
          popups: popups.map((p) => ({
            name: p.name,
            article: p.article,
            radius: p.radius,
            geolocation: p.geolocation
          }))
        }
      }
    })
    await refetch()
  };

  const handleChangePopup = useCallback(
    (popup: any, dataChanged: boolean) => {
      if (!scour) return;
      if (dataChanged) {
        const newPopups = [...popups];
        let item = newPopups.find((pu) => pu.id === popup.id);
        if (item) {
          newPopups[newPopups.indexOf(item)] = popup;
          setPopups(newPopups);
        }
      }
      if (!dataChanged) {
        setPopups(scour.popups.map((p: any) => ({
          id: p.id,
          article: p.article,
          name: p.name,
          radius: p.radius,
          geolocation: {
            latitude: p.geolocation.latitude,
            longitude: p.geolocation.longitude
          }
        })));
      }
    },
    [scour, popups]
  );

  const handleAddPopup = () => {
    if (isDisabled || !scour) {
      return
    }
    updateScour({
      variables: {
        input: {
          name: scour.name,
          scourId,
          description: scour.description,
          additionalInfo: scour.additionalInfo,
          duration: scour.duration,
          level: scour.level,
          beta: scour.beta,
          hidden: scour.hidden,
          tags: scour.tags,
          popups: [...scour.popups.map((p: any) => ({
            name: p.name,
            article: p.article,
            radius: p.radius,
            geolocation: p.geolocation
          })), {
            name: 'New popup',
            article: {
              images: [],
              text: ''
            },
            radius: 30,
            geolocation: {
              latitude: currentGeolocation?.latitude ?? 50,
              longitude: currentGeolocation?.longitude ?? 50,
            },
          }]
        }
      }
    }).then(async (res) => {
      await refetch()
      setHighlightedPopupId(res.data.updateScour.popups[res.data.updateScour.popups.length - 1].id)
    })
  }

  const handleRemovePopup = useCallback(
    async (id: string) => {
      if (!scour) return
      await updateScour({
        variables: {
          input: {
            name: scour.name,
            scourId,
            description: scour.description,
            additionalInfo: scour.additionalInfo,
            duration: scour.duration,
            level: scour.level,
            beta: scour.beta,
            hidden: scour.hidden,
            tags: scour.tags,
            popups: popups.filter((p) => p.id !== id).map((p) => ({
              name: p.name,
              article: p.article,
              radius: p.radius,
              geolocation: p.geolocation
            }))
          }
        }
      })
      await refetch()
    },
    [scour, scourId, popups]
  );

  const handleDragEnd = async (res: DropResult) => {
    if (!stops || isDisabled) return
    const {destination, source} = res
    if (!destination) return
    if (destination.droppableId !== source.droppableId || destination.index === source.index) return

    let draggableStop = stops[source.index]
    let temp = [...stops]
    const element = temp.splice(temp.indexOf(draggableStop), 1)[0];
    temp.splice(destination.index, 0, element);
    setStops(temp)

    await updateStop({
      variables: {
        input: {
          scourId,
          stopId: stops[source.index].id,
          order: destination.index + 1
        }
      }
    })
    await refetch()
  }

  useEffect(() => {
    if (!scour || !allCities) return
    const city = allCities.cities.find((city: City) => city.tiles.some((t) => t.id === scour.id))
    city && setCurrentGeolocation(city.geofence.geolocation)
  }, [scour, allCities])

  useEffect(() => {
    if (!scour || !description) return;
    const descriptionChanged = 
      description.images !== scour.description.images || description.text !== scour.description.text
    
    name !== scour.name ||
    duration !== scour.duration ||
    descriptionChanged ||
    level !== scour.level ||
    additionalInfo !== scour.additionalInfo ||
    beta !== scour.beta ||
    hidden !== scour.hidden ||
    JSON.stringify(tags) !== JSON.stringify(scour.tags) ||
    JSON.stringify(popups) !== JSON.stringify(scour.popups)
      ? setDataChanged(true)
      : setDataChanged(false);
  }, [
    scour,
    name,
    duration,
    level,
    additionalInfo,
    beta,
    hidden,
    description,
    tags,
    popups,
  ]);

  useEffect(() => {
    setIsDisabled(!scour?.hidden && !roles?.some((role) => role === 'admin' || role === 'internal'))
  }, [scour, roles])

  useEffect(() => {
    if (!data || !scourId) return;
    setScour({
      ...data.scour,
      description: {
        images: data.scour.description.images,
        text: data.scour.description.text
      },
      popups: data.scour.popups.map((p: any) => ({
        id: p.id,
        article: {
          images: p.description.images,
          text: p.description.text,
        },
        name: p.name,
        radius: p.geofence.radius,
        geolocation: {
          latitude: p.geofence.geolocation.latitude,
          longitude: p.geofence.geolocation.longitude
        }
      })),
      stops: data.scour.stops.map((stop: any) => ({
        ...stop,
        location: {
          description: {
            text: stop.location.description.text,
            images: stop.location.description.images,
          },
          geolocation: {
            latitude: stop.location.geolocation.latitude,
            longitude: stop.location.geolocation.longitude,
          },
          name: stop.location.name,
          stories: stop.location.stories,
          lastStoryImg: stop.location.lastStoryImg
        },
        challenges: stop.challenges.map((ch: any) => ({
          ...ch,
          ...(ch.hint && { hint: {
            text: ch.hint.text,
            ...(ch.hint.image && { image: ch.hint.image })
          }}),
          ...(ch.navigation && { navigation: {
            ...(ch.navigation.name && { name: ch.navigation.name }),
            ...(ch.navigation.image && { image: ch.navigation.image }),
            ...(ch.navigation.geolocation && { geolocation: {
              latitude: ch.navigation.geolocation.latitude,
              longitude: ch.navigation.geolocation.longitude 
            }}),
          }}),
          answerSet: {
            kind: ch.answerSet.kind,
            ...(ch.answerSet.options && { options: ch.answerSet.options }),
            correct:
              (ch.answerSet.multipleCorrect && (Array.isArray(ch.answerSet.multipleCorrect) ? ch.answerSet.multipleCorrect.map((c: any) => c.toString()) : [ch.answerSet.multipleCorrec.toString()])) ??
              (ch.answerSet.numberCorrect && (Array.isArray(ch.answerSet.numberCorrect) ? ch.answerSet.numberCorrect.map((c: any) => c.toString()) : [ch.answerSet.numberCorrect.toString()])) ??
              ch.answerSet.stringCorrect,
          },
        })),
      }))
    });
    setName(data.scour.name);
    setDescription({
      images: data.scour.description.images,
      text: data.scour.description.text
    });
    setAdditionalInfo(data.scour.additionalInfo);
    setDuration(data.scour.duration);
    setLevel(data.scour.level);
    setBeta(data.scour.beta);
    setHidden(data.scour.hidden);
    setTags(data.scour.tags);
    setStops(
      data.scour.stops.map((stop: any) => ({
        ...stop,
        location: {
          description: {
            text: stop.location.description.text,
            images: stop.location.description.images,
          },
          geolocation: {
            latitude: stop.location.geolocation.latitude,
            longitude: stop.location.geolocation.longitude,
          },
          name: stop.location.name,
          stories: stop.location.stories,
          lastStoryImg: stop.location.lastStoryImg
        },
        challenges: stop.challenges.map((ch: any) => ({
          ...ch,
          ...(ch.hint && { hint: {
            text: ch.hint.text,
            ...(ch.hint.image && { image: ch.hint.image })
          }}),
          ...(ch.navigation && { navigation: {
            ...(ch.navigation.name && { name: ch.navigation.name }),
            ...(ch.navigation.image && { image: ch.navigation.image }),
            ...(ch.navigation.geolocation && { geolocation: {
              latitude: ch.navigation.geolocation.latitude,
              longitude: ch.navigation.geolocation.longitude 
            }}),
          }}),
          answerSet: {
            kind: ch.answerSet.kind,
            ...(ch.answerSet.options && { options: ch.answerSet.options }),
            correct:
              (ch.answerSet.multipleCorrect && (Array.isArray(ch.answerSet.multipleCorrect) ? ch.answerSet.multipleCorrect.map((c: any) => c.toString()) : [ch.answerSet.multipleCorrec.toString()])) ??
              (ch.answerSet.numberCorrect && (Array.isArray(ch.answerSet.numberCorrect) ? ch.answerSet.numberCorrect.map((c: any) => c.toString()) : [ch.answerSet.numberCorrect.toString()])) ??
              ch.answerSet.stringCorrect,
          },
        })),
      }))
    );
    setPopups(data.scour.popups.map((p: any) => ({
      id: p.id,
      article: {
        images: p.description.images,
        text: p.description.text,
      },
      name: p.name,
      radius: p.geofence.radius,
      geolocation: {
        latitude: p.geofence.geolocation.latitude,
        longitude: p.geofence.geolocation.longitude
      }
    })));
  }, [scourId, data]);

  window.onbeforeunload = () => {
    if (dataChanged)
      return "Are you sure you want to leave? You have an unsaved data";
  };

  if (!scour || !stops || !popups || !roles) return <Loading />;

  return (
    <>
      <PageHeader />
      {isDisabled && <ModifyWarning />}
      {dataChanged && !isDisabled && (
        <SaveButton handleClick={handleUpdateScour}>
          Save
        </SaveButton>
      )}
      <PageTitle>{scour.name}</PageTitle>
      <QuarterWrapper>
        <label>Name</label>
        <TextInput 
          placeholder="name"
          disabled={isDisabled}
          value={name}
          handleChange={(e) => setName(e.target.value)}
        />
      </QuarterWrapper>
      <ContentWrapper>
        <FlexContainer>
          <label>AdditionalInfo</label>
          <InfoButton handleClick={() => {
            setShowFieldInfoModal(true)
            setFieldToShow({limitation: 100, description: 'Please, provide an additional info of current scour', image: scourAdditionalInfo})
          }}/>
        </FlexContainer>
        <TextArea value={additionalInfo ?? ''} disabled={isDisabled} placeholder="article content" handleChange={(e) => setAdditionalInfo(e.target.value)} />
      </ContentWrapper>
      <div className="column is-one-fifth px-5 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
        <FlexContainer>
          <label>Duration</label>
          <InfoButton handleClick={() => {
            setShowFieldInfoModal(true)
            setFieldToShow({limitation: 5, description: 'Please, provide a duration of current scour', image: scourInfo})
          }}/>
        </FlexContainer>
        <input
          type="number"
          className="input"
          disabled={isDisabled}
          value={duration}
          max={99999}
          onChange={(e) => setDuration(Number(e.target.value.slice(0, 5)))}
        />
      </div>
      <div className="column is-one-fifth px-5 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
        <label>Level</label>
        <div className="select">
          <select
            disabled={isDisabled}
            onChange={(e) => {
              switch (e.target.value) {
                case "EASY":
                  return setLevel(e.target.value);
                case "INTERMEDIATE":
                  return setLevel(e.target.value);
                case "HARD":
                  return setLevel(e.target.value);
              }
            }}
            style={{ width: "100%" }}
            value={level}
          >
            <option value="EASY">EASY</option>
            <option value="INTERMEDIATE">INTERMEDIATE</option>
            <option value="HARD">HARD</option>
          </select>
        </div>
      </div>
      <div className="column is-one-quarter px-5 py-0 mt-3 mb-5">
        <label className="is-flex is-align-items-center">
          Beta
          <input
            disabled={isDisabled}
            type="checkbox"
            checked={beta}
            className="ml-3"
            onChange={(e) => setBeta(e.target.checked)}
          />
        </label>
      </div>
      {roles.some((role) => role === "admin" || role === "internal") && (
        <div className="column is-one-quarter px-5 py-0 mt-3 mb-5">
          <label className="is-flex is-align-items-center">
            Hidden
            <input
              type="checkbox"
              checked={hidden}
              className="ml-3"
              onChange={(e) => setHidden(e.target.checked)}
            />
          </label>
        </div>
      )}
      <ContentWrapper>
        <Subtitle>Description</Subtitle>
        {description && ( 
          <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
            <label>Images</label>
            <div className="is-flex">
              {description.images.map((img, idx) => (
                <div key={idx} className="is-relative">
                  {isVideo(img) ?
                    <video 
                      src={img}
                      style={{ width: "150px", height: "150px" }}
                      className="mr-2"
                      autoPlay
                      muted
                      loop
                    />
                  :
                    <img
                      src={img}
                      style={{ width: "150px", height: "150px" }}
                      className="mr-2"
                      alt="img"
                    /> 
                  }
                  {!isDisabled && 
                    <RemoveButton 
                      handleClick={() =>
                        setDescription({
                          ...description,
                          images: description.images.filter(
                            (image) => image !== img
                          ),
                        }
                      )}
                      additionalStyle={{
                        position: "absolute",
                        right: "10px",
                        top: "0",
                      }}
                    />
                  }
                </div>
              ))}
              {!isDisabled && <div className="file">
                <label className="file-label">
                  <FileInput handleChange={async (e) => {
                    const url = await getUploadedImageUrl(e)
                    url && setDescription(description ? {...description, images: [...description.images, url]} : {images: [url], text: ''})
                  }}/>
                  <span
                    className="file-cta is-flex is-justify-content-center"
                    style={{ width: "150px", height: "150px" }}
                  >
                    <span className="file-label is-size-1">+</span>
                  </span>
                </label>
              </div>}
            </div>
            <div className="column is-full px-1 py-0 mt-3 mb-5 is-flex is-flex-direction-column">
              <FlexContainer>
                <label>Text</label>
                <InfoButton handleClick={() => {
                  setShowFieldInfoModal(true)
                  setFieldToShow({limitation: 2500, description: 'Please, provide a description of current scour', image: scourInfo})
                }}/>
              </FlexContainer>
              <MDEditor
                value={description.text}
                onChange={(str) => setDescription({...description, text: str?.slice(0, 2500) ?? ''})}
              />
            </div>
          </div>   
        )}
      </ContentWrapper>
      <div className="column is-flex is-full px-5 py-0 mt-3">
        <Subtitle>Tags</Subtitle>
      </div>
      <ul className="m-5">
        {tags &&
          tags.map((tag, idx) => (
            <li
              className="column is-half is-flex is-align-items-center"
              key={idx}
            >
              <TextInput 
                marginRight={true}
                disabled={isDisabled}
                value={tag}
                handleChange={(e) => {
                  const newTags = [...tags]
                  newTags[idx] = e.target.value
                  setTags(newTags)
                }}
              />
              {!isDisabled && 
                <RemoveButton 
                  handleClick={() => {
                    let newTags = [...tags]
                    newTags.splice(idx, 1)
                    setTags(newTags)
                  }}
                />
              }
            </li>
          ))}
        <button
          disabled={isDisabled}
          onClick={() => setTags(tags ? [...tags, "New tag"] : ["New tag"])}
          className="button is-light mt-5 mr-5"
        >
          Add Tag
        </button>
      </ul>
      <div className="column is-flex is-full px-5 py-0 mt-3">
        <Subtitle>Stops</Subtitle>
        <button onClick={() => setIsStopActive([])} className="button ml-5">
          collapse all
        </button>
        <button
          onClick={() => setIsStopActive(stops.map((_, idx) => idx))}
          className="button ml-5"
        >
          expand all
        </button>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="stops">
          {(droppableProvided) => 
            <ul
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              className="m-5"
            >
              {stops.map((stop, idx) => (
                <StopItem
                  key={stop.id}
                  stop={stop}
                  idx={idx}
                  isActive={isStopActive}
                  handleActive={setIsStopActive}
                  highlightedStopId={highlightedStopId}
                  isDisabled={isDisabled}
                  refetch={refetch}
                />
              ))}
              {droppableProvided.placeholder}
            </ul>
          }
        </Droppable>
      </DragDropContext>
      <button
        disabled={isDisabled}
        onClick={() => {
          if (isDisabled) {
            return
          }
          addStop({
            variables: {
              input: {
                scourId,
                stop: {
                  challenges: [],
                  location: {
                    name: 'City Location',
                    description: {
                      text: 'Description Text',
                      images: []
                    },
                    geolocation: {
                      longitude: currentGeolocation?.longitude ?? 50,
                      latitude: currentGeolocation?.latitude ?? 50,
                    },
                    stories: [],
                    lastStoryImg: ''
                  },
                  navigationInfo: 'Navigation Info'
                }
              }
            }
          }).then(async (res) => {
            await refetch()
            setHighlightedStopId(res.data.addScourStop.id)
          })
        }}
        className="button is-light m-5"
      >
        Add Stop
      </button>
      <div className="column is-flex is-full px-5 py-0 mt-3">
        <Subtitle>Popups</Subtitle>
        <button onClick={() => setIsPopupActive([])} className="button ml-5">
          collapse all
        </button>
        <button
          onClick={() => setIsPopupActive(scour?.stops.map((_, idx) => idx))}
          className="button ml-5"
        >
          expand all
        </button>
      </div>
      <ul className="m-5">
        {scour.popups.map((popup: any, idx) => (
          <PopupItem
            key={popup.id}
            popup={popup}
            idx={idx}
            isActive={isPopupActive}
            handleActive={setIsPopupActive}
            handleChangePopup={handleChangePopup}
            handleRemovePopup={handleRemovePopup}
            highlightedPopupId={highlightedPopupId}
            isDisabled={isDisabled}
          />
        ))}
      </ul>
      <button
        disabled={isDisabled}
        onClick={handleAddPopup}
        className="button is-light m-5"
      >
        Add Popup
      </button>
      {showFieldInfoModal && fieldToShow && <FieldInfoModal fieldToShow={fieldToShow} handleModalClose={handleInfoModalClose} />}
    </>
  );
};

export default ScourPage;
