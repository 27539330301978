import React, { useEffect } from "react";
import { useNullableUser } from "./components/AuthRoute";
import useRoles from "./hooks/useRoles";
import { firebase } from "./services/firebase";

export const ALLOWED_ROLES = ["admin", "internal", "content_partner"];

export interface ContextType {
  roles: string[] | undefined;
}

export const Context = React.createContext<ContextType | undefined>(undefined);

export const UIProvider: React.FC = ({ children }) => {
  const roles = useRoles();
  const [user] = useNullableUser();

  useEffect(() => {
    if (!user || !roles) return;
    if (
      !roles.some((role) => ALLOWED_ROLES.some((allowed) => allowed === role))
    ) {
      alert("Admin permission required!");
      firebase.auth().signOut();
    }
  }, [user, roles]);

  let state = {
    roles,
  };

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default Context as React.Context<ContextType>;
