import { BaseSyntheticEvent } from "react"

interface FileInputProps {
  handleChange: (e: BaseSyntheticEvent) => void
}

export const FileInput = ({ handleChange }: FileInputProps) => (
  <input 
    className="file-input" 
    type="file" 
    name="resume" 
    multiple={false} 
    onChange={handleChange}
  />
)